import { ApplicationInsights  } from '@microsoft/applicationinsights-web';
import { Route } from 'vue-router';

// Get instrumentation key from build settings
const appInsightsConnString = process.env.VUE_APP_APPINSIGHTS_CONNECTION_STRING;

/**
 * Usage:
 * Vue.use(applicationInsights, {
 *   router: router
 * })
 */
function install(Vue: any, options: any) {
  if (!appInsightsConnString) {
      // Do nothing if instrumentation key is not set
      return;
  }
  Vue.appInsights = new ApplicationInsights({ config: {
    connectionString: appInsightsConnString
    /* ...Other Configuration Options... */
  } });
  Vue.appInsights.loadAppInsights();
  // Track vue errors to application insights
  Vue.config.errorHandler = (exception: Error, component: any, info: string) => {

    const routeName = (component.$route?.name
      ? component.$route.name
      : "(none)")

    const message = exception.message;

    const componentName = component?._name
        ? component._name
        : "(none)";

    Vue.appInsights.trackException({
      exception,// new Error(errorMessage, { stack': }),
      properties: {message, routeName, componentName}
    }
    );
    console.error(exception);
  };

  // Setup Vue router to track pages if router is passed in with options
  const router = options.router;
  if (router) {
    const baseName = options.baseName || '(Rideit)';

    router.beforeEach( (route: Route, from: Route, next: () => void) => {
      const name = baseName + ' / ' + route.name;
      Vue.appInsights.startTrackPage(name);
      next();
    });

    router.afterEach((route: Route) => {
      const name = baseName + ' / ' + route.name;
      const url = location.protocol + '//' + location.host + route.fullPath;
      Vue.appInsights.stopTrackPage(name, url);
    });
  }

  // Define getter to all Vue components
  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => Vue.appInsights,
  });
}

export default install;
