






















import { Component, Vue } from 'vue-property-decorator';
import MessageItem from '@/components/MessageItem.vue';
import MessageForm from '@/components/MessageForm.vue';
import restService from '@/services/restService';

@Component({
  components: {
    MessageItem,
    MessageForm,
  },
})
export default class Messages extends Vue {
  loading: boolean = false;
  heightBeforeLoading: number = 0;
  message: string = '';
  sendFromValid: boolean = false;

  get messages(): IMessage[] {
    return this.$store.state.message.messages;
  }

  get canLoadMore() {
    return this.$store.getters['message/canLoadMore'];
  }

  get moreThanOnePassenger() {
    return this.messages.map(m => m.passengerId)
      .filter((p, i, a) => a.indexOf(p) === i)
      .length > 1;
  }

  created() {
    window.addEventListener('scroll', this.onScroll);
  }

  mounted() {
    this.scrollToEnd();
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    if (window.scrollY === 0 && !this.loading) {
      this.loadMoreMessages();
    }
  }

  scrollTo() {
    // After rendering new messages scoll back to the position before loading
    this.$nextTick(() => {
      const currentHeight = document.body.scrollHeight;
      const diffrence = currentHeight - this.heightBeforeLoading;
      window.scrollTo(0, diffrence);
    });
  }

  scrollToEnd() {
    this.$nextTick(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  loadMoreMessages() {
    if (this.canLoadMore) {
      // Display loading circle and when its rendered fetch data
      this.loading = true;
      this.$nextTick(async () => {
        // Get document heigth after drawing loading circle
        this.heightBeforeLoading = document.body.scrollHeight;
        await this.$store.dispatch('message/loadPreviousPage');
        this.loading = false;
        this.scrollTo();
      });
    }
  }
}
