









import { Component, Vue } from 'vue-property-decorator';
import { INoticeState } from '../store/notice-store';

@Component
export default class NoticeBar extends Vue {

  get noticeState(): INoticeState {
    return this.$store.state.notice;
  }

  get show() {
    return this.noticeState.show;
  }
  set show(val) {
    this.$store.commit('notice/hideNotice');
  }

  get text() {
    return this.noticeState.text;
  }

  get color() {
    return this.noticeState.color;
  }

  get button() {
    return this.noticeState.button;
  }

  buttonClicked() {
    if (this.noticeState.link) {
      this.$router.push({ path: this.noticeState.link });
    }
    this.$store.commit('notice/hideNotice');
  }
}
