export default class GoogleMaps  {

    install(Vue: any, options: { apiKey: string, version?: string, language?: string }) {

      if (!options.apiKey) {
        // throw new Error('Cannot set up GoogleMaps, no api key defined');
      }

      if (typeof options.apiKey !== 'string') {
        // throw new Error('Cannot set up GoogleMaps, api key is not a string');
      }

      try {

        if (typeof window !== 'undefined') {
          (window as any).vgaMapState = {
            initMap: false,
          };

          (window as any).initVGAMaps = () => {
            (window as any).vgaMapState.initMap = true;
          };
        }

        // If not within browser context, do not continue processing.
        if (typeof (window as any) === 'undefined' || typeof document === 'undefined') {
          return;
        }

        if (typeof (window as any).google === 'object' && typeof (window as any).google.maps === 'object') {
          if (typeof (window as any).google.maps.places === 'object') {
            return; // google is already loaded, don't try to load it again to prevent errors
          }
          throw new Error('Google is already loaded, but does not contain the places API.');
        }

        if (!(window as any).vgaMapState.initMap) {
          const googleMapScript = document.createElement('SCRIPT');
          const params = {
            key: options.apiKey,
            libraries: 'places',
            callback: 'initVGAMaps',
          };

          const paramsString = Object.keys(params)
            .map((key: any) => `${encodeURIComponent(key)}=${encodeURIComponent((params as any)[key])}`)
            .join('&');

          let url = `https://maps.googleapis.com/maps/api/js?${paramsString}`;

          if (options.version) {
            url = `${url}&v=${options.version}`;
          }

          if (options.language) {
            url = `${url}&language=${options.language}`;
          }
          googleMapScript.setAttribute('src', url);
          googleMapScript.setAttribute('async', '');
          googleMapScript.setAttribute('defer', '');
          document.body.appendChild(googleMapScript);
        } else {
          throw new Error('Google Maps API loaded multiple times.');
        }
      } catch (exception) {
        throw new Error('Google Maps API load error: ' + exception);
      }

    }
  }

