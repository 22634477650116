


















































import moment, { Moment } from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';

export enum TripStatusCode {
  Unknown = 0,
  Booked = 1,
  Routed = 2,
  Dispatching = 3,
  Dispatched = 4,
  Cancelled = 5,

  EnRoute = 10,
  CarWaiting = 11,
  NoShow = 12,
  Pickup = 13,
  DropOff = 14,
}

@Component
export default class TripTrackingStatus extends Vue {
  // Enum usable by template
  tripStatusCode = TripStatusCode;
  creationTime: Moment | null = null;
  updateInterval: any;

  @Prop() private trip!: ITrip;

  created() {
    this.creationTime = moment();
  }

  get isTrackingTrip() {
    return this.$route.name === 'track';
  }

  get showTime() {
    switch (this.trip.status!.code) {
      case TripStatusCode.Cancelled:
      case TripStatusCode.NoShow:
      case TripStatusCode.DropOff:
        return false;
      default:
        return true;
    }
  }

  get showCancelled() {
    switch (this.trip.status!.code) {
      case TripStatusCode.Cancelled:
      case TripStatusCode.NoShow:
        return true;
      default:
        return false;
    }
  }

  get showCompleted() {
    return this.trip.status!.code === TripStatusCode.DropOff;
  }

  get statusColor() {
    switch (this.trip.status?.code) {
      case TripStatusCode.EnRoute:
        return 'orange';
      case TripStatusCode.Pickup:
      case TripStatusCode.DropOff:
        return 'green';
      case TripStatusCode.NoShow:
      case TripStatusCode.Cancelled:
        return 'red';
      default:
        return 'primary';
    }
    return 'primary';
  }

  get statusText() {
    if (this.trip && this.trip.status) {
      switch (this.trip.status.code) {
        case TripStatusCode.EnRoute:
          return 'tripStatus.en_route.status';
        case TripStatusCode.Pickup:
          return 'tripStatus.pickup.status';
        case TripStatusCode.DropOff:
          return 'tripStatus.dropoff.status';
        case TripStatusCode.Cancelled:
          return 'tripStatus.cancelled.status';
        case TripStatusCode.NoShow:
          return 'tripStatus.no_show.status';
        default:
          return 'tripStatus.unknown.status';
      }
    }
    return '';
  }

  get estimationText() {
    switch (this.trip.status!.code) {
      case TripStatusCode.DropOff:
        return 'tripStatus.dropoff.text';
      case TripStatusCode.Cancelled:
        return this.trip.cancelReason || 'tripStatus.cancelled.text';
      case TripStatusCode.NoShow:
        return 'tripStatus.no_show.text';
      case TripStatusCode.Pickup:
        return 'tripStatus.pickup.text';
      default:
        return 'tripStatus.unknown.text';
    }
  }

  get estimatedEndTime() {
    const ata = moment(this.trip.status!.ata);
    if (ata.year() > 1) { return ata; }

    const eta = moment(this.trip.status!.eta);
    if (eta.year() > 1) { return eta; }

    const tripEndTime = moment(this.trip.endDateTime);
    return tripEndTime;
  }

  get estimatedMinutes() {
    const now = moment();
    let timeDiff = 0;
    if (this.trip.status!.code < TripStatusCode.Pickup) {
      timeDiff = this.estimatedStartTime.diff(now, 'm');
    } else {
      timeDiff = this.estimatedEndTime.diff(now, 'm');
    }
    return timeDiff > 0 ? timeDiff : 0;
  }

  get estimatedStartTime() {
    if (this.isTrackingTrip || !this.trip.status) {
      const startTime = moment(this.trip.startDateTime);
      const endTime = moment(this.trip.endDateTime);
      const timeDiff = moment(this.estimatedEndTime).diff(endTime, 'm');
      const adjustedStart = startTime.add(timeDiff, 'm');
      return adjustedStart;
    }
    const etd = moment(this.trip.status!.etd);
    if (etd.year() > 1) {
      return etd;
    }

    return moment(this.trip.startDateTime);
  }

  get timeProgress() {
    if (this.showCancelled || this.showCompleted) { return 100; }

    if (this.estimatedMinutes === 0) { return 95; }

    const now = moment();
    let totalMinutes = 0;
    if (this.trip.status!.code < TripStatusCode.Pickup) {
      totalMinutes = this.estimatedStartTime.diff(this.creationTime, 'm');
    } else if (this.trip.status!.code === TripStatusCode.Pickup) {
      totalMinutes = this.trip.pickupTime ?
        this.estimatedEndTime.diff(moment(this.trip.pickupTime), 'm')
        : this.estimatedEndTime.diff(this.estimatedStartTime, 'm');
    } else if (this.trip.status!.code < TripStatusCode.DropOff) {
      totalMinutes = this.estimatedEndTime.diff(this.estimatedStartTime, 'm');
    }

    const waitedMinutes = totalMinutes - this.estimatedMinutes;
    const timeProgress = ( waitedMinutes / totalMinutes ) * 100;

    return timeProgress < 10 ? 10 : Math.round(timeProgress);
  }

  get estimatedTime() {
     switch (this.trip.status!.code) {
      case TripStatusCode.DropOff:
        return moment(this.trip.status!.ata);
      case TripStatusCode.Cancelled:
        return '';
      case TripStatusCode.NoShow:
        return moment(this.trip.status!.ata);
      case TripStatusCode.Pickup:
        return this.estimatedEndTime;
      default:
        return this.estimatedStartTime;
    }
  }

}
