import restService from '@/services/restService';
import { Module } from 'vuex';
import { IRootState } from './store';
import cacheService from '@/services/cacheService';

export interface IPassengerState {
  passengers: IPassenger[];
}

// STATE
const passengerState: IPassengerState = {
  passengers: [],
};

// GETTERS
const getters = {
  passengers: (state: IPassengerState) => state.passengers,
  passengerById: (state: IPassengerState) => (id: string) =>
    state.passengers.filter(p => p.id === id)[0],
};

// MUTATIONS
const mutations = {
  passengerReplaceOrAdd: (state: IPassengerState, payload: IPassenger) => {
    const passenger = state.passengers.find(p => p.id === payload.id);
    if (passenger) {
      const index = state.passengers.indexOf(passenger);
      state.passengers.splice(index, 1, payload);
    } else {
      state.passengers.push(payload);
    }
    state.passengers.sort((p1, p2) => p1.name.localeCompare(p2.name));
  },
  clearUserData: (state: IPassengerState) => {
    state.passengers = [];
  },
};

// ACTIONS
const actions = {
  async initializePassengers({ commit, dispatch }: { commit: any; dispatch: any }) {
    try {
      // Get all passengers
      const passengers = await restService.passenger.get();
      await cacheService.clearMissingPassengersFromCache(passengers);
      // Add / update passengers
      passengers.forEach(passenger =>
        commit('passengerReplaceOrAdd', passenger),
      );
      return passengers;
    } catch (error) {
      commit('app/error', (error as any).message || error, { root: true });
    }
  },
  async fetchTrips({ state, commit, dispatch }: { state: IPassengerState, commit: any; dispatch: any }) {
    try {
      // Get trips for each passenger
      await Promise.all(
        state.passengers.map(passenger => {
          return dispatch('getPassengerTrips', passenger);
        }),
      );
      return state.passengers;
    } catch (error) {
      commit('app/error', (error as any).message || error, { root: true });
    }
  },
  async getPassengerTrips({ commit }: { commit: any }, passenger: IPassenger) {
    let trips: ITrip[] = [ ];
    try {
      if (!('caches' in window)) {
        const localTrips: any = JSON.parse(localStorage.getItem(`/api/passenger/${passenger.id}/trips`) as any);
        if (localTrips && new Date(localTrips.time).getTime() - new Date().getTime() < 60 * 1000) {
          trips = localTrips.trips;
        } else {
          trips = await restService.passenger.getTrips(passenger.id);
          localStorage.setItem(`/api/passenger/${passenger.id}/trips`, JSON.stringify({
              time: new Date().toISOString,
              trips,
            }),
          );
        }
      } else {
        trips = await restService.passenger.getTrips(passenger.id);
      }
      trips.forEach(trip => { commit('trip/addOrReplaceTrip', trip, { root: true }); });
    } catch (error) {
      commit('app/error', (error as any).message || error, { root: true });
    }
  },
  async acceptInvitation({ commit, dispatch }: { commit: any; dispatch: any }, invite: IUserInvitation) {
    try {
      const tokens = await restService.invitation.accept(invite);
      tokens.forEach(t => {
        commit('passengerReplaceOrAdd', t.passenger);
        dispatch('getPassengerTrips', t.passenger);
      });
    } catch (error) {
      if ((error as any).response && (error as any).response.status === 404) {
        // Token not found or already claimed
      }
      commit('app/error', (error as any).message || error, { root: true });
    }
  },
  async claimPassenger({ commit, dispatch }: { commit: any; dispatch: any }, token: string) {
    try {
      const tokenResp = await restService.passenger.claim(token);
      commit('passengerReplaceOrAdd', tokenResp.passenger);
      dispatch('getPassengerTrips', tokenResp.passenger);
    } catch (error) {
      if ((error as any).response && (error as any).response.status === 404) {
        // Token not found or already claimed
      } else if ((error as any).response && (error as any).response.status === 400) {
        // Validation error
      }
      commit('app/error', (error as any).message || error, { root: true });
      throw error;
    }
  },
  async updatePassenger({ commit }: { commit: any }, passenger: IPassenger) {
    try {
      const updatedPassenger = await restService.passenger.put(passenger);
      commit('passengerReplaceOrAdd', updatedPassenger);
      commit('trip/updatePassenger', updatedPassenger, { root: true });
      return updatedPassenger;
    } catch (error) {
      commit('app/error', (error as any).message || error, { root: true });
    }
  },
  async pollTrips({ state , dispatch }: { state: IPassengerState , dispatch: any }) {
    state.passengers.forEach(async (passenger: IPassenger) => {
      const trips = await restService.passenger.getTrips(passenger.id);
      trips.forEach(trip => {
        dispatch('trip/updateTrip', trip, { root: true });
      });
    });
  },
};

const passengerStore: Module<IPassengerState, IRootState> = {
  namespaced: true,
  state: passengerState,
  mutations,
  actions,
  getters,
};

export default passengerStore;
