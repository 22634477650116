































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import restService from "@/services/restService";

@Component({
  name: "PassengerEdit"
})
export default class PassengerEdit extends Vue {
  @Prop({ required: true })
  passenger!: IPassenger;

  @Watch("passenger", { immediate: true })
  passengerChanged(newVal: IPassenger, oldVal: IPassenger) {
    if (newVal) {
      this.editedPassenger = JSON.parse(JSON.stringify(newVal));
    }
  }

  @Prop({ required: true, default: false })
  dialogOpen!: boolean;
  @Watch("dialogOpen", { immediate: true })
  dialogOpenChanged(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.editedPassenger = JSON.parse(JSON.stringify(this.passenger));
    } else {
      this.editedPassenger = null;
    }
  }

  passengerFormValid: boolean = true;
  passengerInitalRules = [(v: string) => this.intialLengthRule(v)];
  editedPassenger: any = null;
  colors = [
    "#D32F2F",
    "#C2185B",
    "#7B1FA2",
    "#512DA8",
    "#303F9F",
    "#1976D2",
    "#0288D1",
    "#0097A7",
    "#00796B",
    "#388E3C",
    "#689F38",
    "#AFB42B",
    "#FBC02D",
    "#FFA000",
    "#F57C00",
    "#E64A19",
    "#5D4037",
    "#616161",
    "#455A64",
  ];

  dialog = false;

  token: string = "";

  get passengers(): IPassenger[] {
    return this.$store.getters["passenger/passengers"];
  }

  intialLengthRule(v: string) {
    return (
      (v && v.length > 0 && v.length < 3) ||
      this.$t("error.length_between_one_and_two")
    );
  }

  closePassengerDialog(saveSuccess: boolean, saveError: boolean) {
    this.$emit("close", { saveSuccess, saveError });
  }

  async savePassenger() {
    let saveSuccess = false;
    let saveError = false;
      // Only call save if data has changed
    try {
      if (this.passenger.avatarText !== this.editedPassenger.avatarText) {
        await this.$store.dispatch(
          "passenger/updatePassenger",
          this.editedPassenger
        );  
      }
      saveSuccess = true;
      saveError = false;
    } catch (error) {
      saveSuccess = false;
      saveError = true;
    }
    this.closePassengerDialog(saveSuccess, saveError);
  }

  mounted() {
  }
}
