class CacheService {

  async clearMissingPassengersFromCache(passengers: IPassenger[]) {
    const passengerIds = passengers.map(p => p.id);
    const cachedPassengers = await this.cachedPassengers();
    const clearPassengers = cachedPassengers.filter(p => passengerIds.indexOf(p) < 0);
    return Promise.all(clearPassengers.map(p => this.clearPassengerTrips(p)));
  }

  async cachedPassengers() {
    if ('caches' in window) {
      const passengerUrl = '/api/passenger/';
      const cache = await window.caches.open('api');
      const requests = await cache.keys();
      return requests.filter(r => r.url.indexOf(passengerUrl) > -1)
        .map(r => {
          const index = r.url.indexOf(passengerUrl);
          return r.url.substring(index + passengerUrl.length).split('/')[0];
        });
    } else {
      return Promise.resolve<string[]>([]);
    }
  }

  async clearPassengerTrips(passengerId: string) {
    if ('caches' in window) {
      const tripUrl = `/api/passenger/${passengerId}/trips`;
      const cache = await window.caches.open('api');
      const requests = await cache.keys();
      await Promise.all(requests.filter(r => r.url.indexOf(tripUrl) > -1)
        .map(r => cache.delete(r)));
    }
  }

  async clearApiCache() {
    return this.clearCache('api');
  }

  private async clearCache(cacheName: string) {
    if ('caches' in window) {
      const cache = await window.caches.open(cacheName);
      const requests = await cache.keys();
      for (const request of requests) {
          await cache.delete(request);
      }
    }
  }
}

export default new CacheService();
