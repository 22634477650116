import './theme_lapha.scss';
import ITheme from '../ITheme';
import NavigationItems from '@/navigationItems';

export default class ThemeLapha implements ITheme {
    id: string = 'lapha';
    matchIfHostNameContainsAny: string[] = ['lapha'];
    name: string = 'Lapha';
    cssClassName: string = 'theme_lapha';
    dark: boolean = false;
    menuDark: boolean = false;
    toolbarDark: boolean = true;
    bottomNavDark: boolean = true;
    menuRight: boolean = false;
    booking: boolean = true;
    menuNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.booking,
        NavigationItems.personalInfo,
        NavigationItems.messages,
        NavigationItems.register,
        NavigationItems.accessibilityReport,
        NavigationItems.privacy,
    ];
    bottomNavNavigationItems: INavigationItem[] = [
        NavigationItems.booking,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.messages,
    ];
    vuetifyTheme: any = {
        primary: '#333399',
        secondary: '#FFCC00',
        accent: '#FFCC00',
        warning: '#FFCC00',
        error: "#EB0000",
        red: "#EB0000",
        grey: "#767676",
        bottomNavColor: '#ffffff'
    };
}
