import './theme_espoo.scss';
import ITheme from '../ITheme';
import NavigationItems from '@/navigationItems';

export default class ThemeEspoo implements ITheme {
    id: string = 'espoo';
    matchIfHostNameContainsAny: string[] = ['ekyyti.'];
    name: string = 'Kuljetuspalvelut';
    cssClassName: string = 'theme_espoo';
    dark: boolean = false;
    menuDark: boolean = false;
    toolbarDark: boolean = false;
    bottomNavDark: boolean = true;
    menuRight: boolean = true;
    booking: boolean = true;
    menuNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.booking,
        NavigationItems.personalInfo,
        NavigationItems.messages,
        NavigationItems.accessibilityReport,
        NavigationItems.privacy,
    ];
    bottomNavNavigationItems: INavigationItem[] = [
        NavigationItems.booking,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.messages,
    ];
    vuetifyTheme: any = {
        primary: '#0047b6',
        secondary: '#00ff00',
        accent: '#0047b6',
        warning: '#00b7f1',
        bottomNavColor: '#ffffff',
    };
}
