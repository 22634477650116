























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import restService from '@/services/restService';
import PassengerListItem from '@/components/PassengerListItem.vue';
import { INotice } from '@/store/notice-store';

@Component({
  components: {
    PassengerListItem
  }
})
export default class Passengers extends Vue {
  dialog = false;
  @Watch("dialog")
  dialogChanged(val: boolean, oldVal: boolean){
    this.claimPassengerError = null;
    if (val){
      this.claimPassengerSuccess = null;
    }
  }
  token: string = '';
  lastSaveSuccess: boolean = false;
  lastSaveError: boolean = false;

  onPassengerEditDialogClose(result: {saveSuccess: boolean, saveError: boolean}) {
    this.lastSaveSuccess = result.saveSuccess && !result.saveError;
    this.lastSaveError = result.saveError && !result.saveSuccess;
  }

  get passengers(): IPassenger[] {
    return this.$store.getters['passenger/passengers'];
  }

  nextTrip(passenger: IPassenger) {
    const nextTrip = this.$store.getters['trip/nextTripForPassenger'](
      passenger,
    );
    if (nextTrip) {
      return nextTrip.startDateTime || nextTrip.endDateTime;
    }
  }

  claimPassengerError: string | null = null;
  claimPassengerSuccess: string | null = null;

  async claimPassenger() {
    this.claimPassengerError = null;
    try {
      if (!this.token || this.token.length < 2) {
        throw {status: 400, data: {message: "Koodi ei kelpaa"}};
      }
      await this.$store.dispatch('passenger/claimPassenger', this.token);
      this.token = '';
      this.dialog = false;
      this.claimPassengerSuccess = this.$t("passenger.success_passenger_added").toString();
    } catch (error) {
      this.claimPassengerError = this.$t("passenger.error_invalid_token").toString();
      (this.$refs["tokenTextFieldRef"] as any).focus();
    }
  }

  mounted() {
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
    this.claimPassengerError = null;
    this.claimPassengerSuccess = null;
  }
}
