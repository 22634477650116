import './theme_tatkela.scss';
import ITheme from '../ITheme';
import NavigationItems from '@/navigationItems';

export default class ThemeTatKela implements ITheme {
    id: string = 'tatkela';
    matchIfHostNameContainsAny: string[] = ['tat']; //TODO: Dangerously short, high false positive probability
    name: string = 'Taksi Tampere';
    cssClassName: string = 'theme_tatkela';
    dark: boolean = false;
    menuDark: boolean = false;
    toolbarDark: boolean = true;
    bottomNavDark: boolean = true;
    menuRight: boolean = false;
    booking: boolean = true;
    menuNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.booking,
        NavigationItems.personalInfo,
        NavigationItems.messages,
        NavigationItems.accessibilityReport,
        NavigationItems.privacy,
    ];
    bottomNavNavigationItems: INavigationItem[] = [
        NavigationItems.booking,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.messages,
    ];
    vuetifyTheme: any = {
        primary: '#050505',
        secondary: '#fae50c',
        accent: '#fae50c',
        warning: '#fae50c',
        bottomNavColor: '#fae50c',
    };
}
