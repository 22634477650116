import './theme_kulkukeskus.scss';
import ITheme from '../ITheme';
import NavigationItems from '@/navigationItems';

export default class ThemeKulkukeskus implements ITheme {
    id: string = 'kulkukeskus';
    matchIfHostNameContainsAny: string[] = ['kulkukeskus'];
    name: string = 'Kulkukeskus';
    cssClassName: string = 'theme_kulkukeskus';
    dark: boolean = false;
    menuDark: boolean = false;
    toolbarDark: boolean = true;
    bottomNavDark: boolean = true;
    menuRight: boolean = false;
    booking: boolean = true;
    menuNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.passengerTransportationPermissions,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.booking,
        NavigationItems.personalInfo,
        NavigationItems.messages,
        NavigationItems.accessibilityReport,
        NavigationItems.privacy,
    ];
    bottomNavNavigationItems: INavigationItem[] = [
        NavigationItems.booking,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.messages,
    ];
    vuetifyTheme: any = {
        primary: '#8052EB',
        secondary: '#c24e0a',
        accent: '#c24e0a',
        warning: '#c24e0a',
        error: "#eb1111",
        red: "#e91b0c",
        grey: "#767676",
        bottomNavColor: '#ffffff'
    };
}
