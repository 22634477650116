
























































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import DateHelper from '@/helpers/DateHelper';

@Component({
  components: {},
})
export default class AccessibleTextInputDatePicker extends Vue {

  @Prop({ default: () => [] })
  errors!: any[];

  @Prop({ default: '' })
  value!: string;

  @Prop({ default: () => new Date().toISOString().substr(0, 10)})
  minDate!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: '' })
  label!: string;

  @Prop({ default: '' })
  ariaLabel!: string;

  uid: string = (Math.random() * 10e16).toString(16);

  get datePickerAnchorName(): string {
    return "datePickerAnchor" + "_" + this.uid;
  }

  get ariaLabelPrefix(): string {
    if (!this.ariaLabel && !this.ariaLabel.trim()){
        return '';
    }
    return this.ariaLabel + ", ";
  }

  @Watch('value', {immediate: true})
  valueChanged(newVal: string, oldVal: string) {
    if (newVal === oldVal || !newVal){
      return;
    }
    var newDate = new Date(newVal);
    this.editedYear = newDate.getFullYear();
    this.editedMonth = newDate.getMonth() + 1;
    this.editedDay = newDate.getDate();
    this.textFieldValue = `${this.editedDay}.${this.editedMonth}.${this.editedYear}`
  }

  textFieldValue = "";
 
  // TODO: Remove when not needed anymore
  startDateOpen: boolean = false;

  todayDate: Date = new Date();
  editedYearValid = true;
  editedMonthValid = true;
  editedDayValid = true;

  twoNumberYearInputSupported = false;

  get rules(): any {
    return {
      notEmpty: (v: string) =>
        !!(this.required && (v ? true : false) && v.trim().length > 0)  || this.$t("error.must_not_be_empty"),
      filledAndValidDateFormat: (v: string) =>
        (!this.isFilled(v) || !!this.dateIsValid(v)) || this.$t("general.check_date_format"),
      filledAndMinDate: (v: string) =>
        (!this.isFilled(v) || !!this.dateIsAtOrAfterIsoDate(v, this.minDate)) ||
        this.$t("general.date_cannot_be_before_date", {
          date: DateHelper.isoDateToFinnishDate(this.minDate),
        }),
    };
  }

  isFilled(v: string): boolean {
    var date = this.parseInputToDate(v);
    if (date){
      return true;
    }
    return false;
  }

  parseInputToDate(v: string): Date | null {
    let date = null;
    v = v.trim();
    if (
      v.length >= 6 && 
      v.indexOf(".") < 0 && 
      v.indexOf("-") < 0 &&
      v.indexOf("/")){
        date = moment(v).toDate();
    }
    let separator = v.indexOf(".") > -1
      ? "."
      : v.indexOf("-")
        ? "-"
        : v.indexOf("/")
          ? "/"
          : null;
    if (separator){
      const parts = v.split(separator);
      let yearInput = "";
      let monthInput = "";
      let dayInput = "";
    
      if (parts.length === 3 && parts.every(p => p.trim().length > 0)) {
        if (separator === "."){
          yearInput = parts[2];
          monthInput = parts[1];
          dayInput = parts[0];
        } else if (separator === "/"){
          yearInput = parts[2];
          monthInput = parts[0];
          dayInput = parts[1];
        } else if (separator === "-"){
          yearInput = parts[0];
          monthInput = parts[1];
          dayInput = parts[2];
        }

        let year = 0;
        let month = 0;
        let day = 0;
            
        if (yearInput.length === 4){
          year = parseInt(yearInput);
        } else if (yearInput.length === 2 && this.twoNumberYearInputSupported){
          let yearCandidate = parseInt(yearInput);
          if (yearCandidate > 20){
            year = yearCandidate + 2000;
          }
        }
        if (monthInput.length > 0 && monthInput.length <= 2){
          month = parseInt(monthInput);
        }
        if (dayInput.length > 0 && dayInput.length <= 2){
          day = parseInt(dayInput);
        }
        if (year > 0 && month > 0 && day > 0){
          console.log("parsed to date ok", year, month, day)
          date = moment(`${year}-${month}-${day}`).toDate();
        }
      }
    }
    return date;
  }

  onTextFieldChange(){
  }

  
  onTextFieldInput(v: string){
    this.editedYearValid = false;
    this.editedMonthValid = false;
    this.editedDayValid = false;

    if (this.dateIsValid(v)){
      var date = this.parseInputToDate(v);
      if (date === null){
        return;
      }
      //this.textFieldValue = v;
      const valMoment = moment(date);
      this.editedYear = valMoment.get("year");
      this.editedMonth = valMoment.get("month") + 1;
      this.editedDay = valMoment.get("date");
      if (!this.isValidYear(this.editedYear)){
        this.editedYearValid = true;
      }
      if (!this.isValidDay(this.editedMonth)){
        this.editedMonthValid = true;
      }
      if (!this.isValidDay(this.editedDay)){
        this.editedDayValid = true;
      }
      this.dateWithoutTime = `${this.editedYear}-${this.editedMonth}-${this.editedDay}`;
    }
  }

  onTextFieldBlur(){
  }


  dateIsAtOrAfterIsoDate(v: string, otherIsoDate: string){
    const date = this.parseInputToDate(v);
    return !moment(date).isBefore(moment(otherIsoDate));
  }

  dateIsValid(val: string): boolean {
    var date = this.parseInputToDate(val);
    console.log("dateIsValid?", date);
    if (!date){
      return false;
    }
    const valMoment = moment(date);
    if (!valMoment.isValid()){
      return false;
    }
    if (valMoment.isBefore("1900-01-01")){
      return false;
    }
    if (valMoment.isAfter("2099-12-31")){
      return false;
    }
    return true;
  }

  get valid(){
    return this.editedYearValid && this.editedMonthValid && this.editedDayValid;
  }
  @Watch('valid')
  validChanged(newVal: boolean, oldVal: boolean){
    console.log("validChanged", newVal);
    this.$emit("validChange", newVal);
  }

  editedYear: number = this.currentYear;
  editedMonth: number = this.currentMonth;
  editedDay: number = this.currentDay;

  isValidYear(val: number){
    return val && !isNaN(val) && val >= 1000 && val <= 9999;
  }
  isValidMonth(val: number){
    return val && !isNaN(val) && val >= 1 && val <= 12;
  }
  isValidDay(val: number){
    const daysInMonth = moment().year(this.editedYear).month(this.editedMonth).daysInMonth();
    return val && !isNaN(val) && val >= 1 && val <= daysInMonth;
  }

  get dateWithoutTime(): string{
    return this.value;
  }
  set dateWithoutTime(val: string){
    this.$emit("change", val);
  }
  
  get currentYear(){
    return this.todayDate.getFullYear();
  }

  get currentMonth(){
    return this.todayDate.getMonth() + 1;
  }
  
  get currentDay(){
    return this.todayDate.getDate();
  }

  get locale() {
    return this.$store.getters['app/locale'];
  }

  mounted(){
    this.todayDate = new Date();
  }

}
