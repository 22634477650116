








































































import { Component, Vue, Prop } from 'vue-property-decorator';
import restService from '@/services/restService';

@Component
export default class MessageForm extends Vue {

  sending = false;
  message: string = '';
  sendFromValid: boolean = false;
  selectedPassenger: IPassenger | null = null;

  @Prop() private passenger!: IPassenger;

  get passengers(): IPassenger[] {
    return this.$store.state.passenger.passengers;
  }

  get showPassengerSelect() {
    return !this.passenger && this.passengers.length > 1;
  }

  get onlyPassenger() {
    if (this.passengers.length === 1) {
      return this.passengers[0];
    }
  }

  passengerRule() {
    return !!this.passenger || !!this.selectedPassenger || this.$t('messages.select_passenger');
  }

  messageRule(value: any) {
    return !!value || this.$t('error.must_not_be_empty');
  }

  async sendMessage() {
    const passenger = this.passenger || this.selectedPassenger || this.onlyPassenger;
    if (this.message && passenger) {
      this.sending = true;
      await this.$store.dispatch('message/sendMessage', { passengerId: passenger.id, body: this.message });
      this.sending = false;
      (this.$refs.form as any).reset();
      this.$emit('messageSent');
    }
  }

  clearMessage() {
    this.message = '';
  }
}
