import RandomColor from "randomcolor"
export class ColorHelper {

  public static getRandomColorWithSeed(
    seed: string | number,
    luminosity: "bright" | "light" | "dark" | "random" = "dark",
    contrastToHex: string = "#ffffff"): string {

    let seedNumber: number = 0;
    if (typeof (seed) === 'string') {
      seedNumber = parseInt(seed, 10);
      if (isNaN(seedNumber)) {
        seedNumber = ColorHelper.getHash(seed);
      }
    } else {
      seedNumber = seed;
    }
    const minContrast = 4.5;
    const minMultiplier = 10000;
    const maxTries = 30;
    let contrast = 0;
    let hsl = [0, 0, 0]
    // Try to get enough contrast for [maxTries] times
    for (var i = minMultiplier; i < minMultiplier + maxTries; i++) {
      const normalizedSeedNumber = Math.floor(Math.sin(seedNumber) * i);
      // RandomColor returns random colors
      // but the seed must have variance for the resulting colors to have variance
      // When format is hsvArray, RandomColor returns an array like [236, 87, 98]
      hsl = RandomColor({
        seed: normalizedSeedNumber.toString(),
        luminosity,
        format: 'hslArray'
      }) as unknown as number[];
      // Force saturation to a specific range (like 30-60)
      hsl[1] = 45 + Math.round(((Math.sin(Math.sin(seedNumber * i)) + 1) / 2) * 45);
      // check contrast
      const rgb = ColorHelper.hslToRgb(hsl[0] / 360, hsl[1] / 100, hsl[2] / 100);
      const hex = ColorHelper.rgbToHex(rgb[0], rgb[1], rgb[2]);
      contrast = ColorHelper.getContrast(contrastToHex, hex);
      if (contrast >= minContrast) {
        // enough contrast, use this color
        break;
      }
    }
    const hslString = `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
    return hslString;

  }

  public static hexToRgb(hexString: string) {
    if (hexString.startsWith("#")) hexString = hexString.substr(1);
    return [
      parseInt(hexString.substring(0, 2), 16),
      parseInt(hexString.substring(2, 4), 16),
      parseInt(hexString.substring(4, 6), 16),
    ];
  };

  public static hue2rgb(p: number, q: number, t: number) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  public static hslToRgb(h: number, s: number, l: number) {
    var r, g, b;
    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = ColorHelper.hue2rgb(p, q, h + 1 / 3);
      g = ColorHelper.hue2rgb(p, q, h);
      b = ColorHelper.hue2rgb(p, q, h - 1 / 3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  public static rgbToHex(r: number, g: number, b: number) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  public static relativeLuminance(rgbArray: number[]) {
    let [r, g, b] = rgbArray.map((channel) => {
      return channel / 255 <= 0.03928
        ? channel / 255 / 12.92
        : ((channel / 255 + 0.055) / 1.055) ** 2.4;
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  public static getContrast(color1: string, color2: string) {
    const luminance1 = ColorHelper.relativeLuminance(ColorHelper.hexToRgb(color1)),
      luminance2 = ColorHelper.relativeLuminance(ColorHelper.hexToRgb(color2));
    return luminance1 > luminance2
      ? (luminance1 + 0.05) / (luminance2 + 0.05)
      : (luminance2 + 0.05) / (luminance1 + 0.05);
  };

  private static getHash(input: string): number {
    var hash = 0, len = input.length;
    for (var i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + input.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return hash;
  }
}
(window as any).ColorHelper = ColorHelper;
export default ColorHelper;