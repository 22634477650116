// Auto generated @ Tue Jun 11 2024 09:34:30 GMT+0300 (Eastern European Summer Time)

//fas
import {
	faUsers as fasUsers,
	faInfo as fasInfo,
	faBusinessTime as fasBusinessTime,
	faClock as fasClock,
	faMapMarker as fasMapMarker,
	faTrashCan as fasTrashCan,
	faPhone as fasPhone,
	faPaperPlane as fasPaperPlane,
	faTimesCircle as fasTimesCircle,
	faCheck as fasCheck,
	faHome as fasHome,
	faStreetView as fasStreetView,
	faSchool as fasSchool,
	faCheckDouble as fasCheckDouble,
	faBan as fasBan,
	faUserXmark as fasUserXmark,
	faTimes as fasTimes,
	faUserCircle as fasUserCircle,
	faClose as fasClose,
	faFileMedical as fasFileMedical,
	faFile as fasFile,
	faTaxi as fasTaxi,
	faCreditCard as fasCreditCard,
	faUser as fasUser,
	faEnvelope as fasEnvelope,
	faUserSecret as fasUserSecret,
	faIdCard as fasIdCard,
	faSignOutAlt as fasSignOutAlt,
	faUnlockAlt as fasUnlockAlt,
	faStar as fasStar,
	faEuro as fasEuro,
	faNoteSticky as fasNoteSticky,
	faTrash as fasTrash
} from '@fortawesome/free-solid-svg-icons';

//far
import {
	faSquareCheck as farSquareCheck,
	faSquare as farSquare,
	faCalendarAlt as farCalendarAlt
} from '@fortawesome/free-regular-svg-icons';

import { library } from "@fortawesome/fontawesome-svg-core";
library.add(
	fasUsers,
	fasInfo,
	fasBusinessTime,
	fasClock,
	fasMapMarker,
	fasTrashCan,
	fasPhone,
	fasPaperPlane,
	fasTimesCircle,
	fasCheck,
	fasHome,
	fasStreetView,
	fasSchool,
	fasCheckDouble,
	fasBan,
	fasUserXmark,
	fasTimes,
	fasUserCircle,
	fasClose,
	fasFileMedical,
	fasFile,
	fasTaxi,
	fasCreditCard,
	fasUser,
	fasEnvelope,
	fasUserSecret,
	fasIdCard,
	fasSignOutAlt,
	fasUnlockAlt,
	farSquareCheck,
	farSquare,
	farCalendarAlt,
	fasStar,
	fasEuro,
	fasNoteSticky,
	fasTrash
);
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
const CUSTOM_ICONS: any = {
    "fas-fa-users": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-users']
        }
    },
    "fas-fa-info": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-info']
        }
    },
    "fas-fa-business-time": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-business-time']
        }
    },
    "fas-fa-clock": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-clock']
        }
    },
    "fas-fa-map-marker": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-map-marker']
        }
    },
    "fas-fa-trash-can": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-trash-can']
        }
    },
    "fas-fa-phone": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-phone']
        }
    },
    "fas-fa-paper-plane": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-paper-plane']
        }
    },
    "fas-fa-times-circle": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-times-circle']
        }
    },
    "fas-fa-check": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-check']
        }
    },
    "fas-fa-home": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-home']
        }
    },
    "fas-fa-street-view": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-street-view']
        }
    },
    "fas-fa-school": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-school']
        }
    },
    "fas-fa-check-double": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-check-double']
        }
    },
    "fas-fa-ban": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-ban']
        }
    },
    "fas-fa-user-xmark": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-user-xmark']
        }
    },
    "fas-fa-times": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-times']
        }
    },
    "fas-fa-user-circle": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-user-circle']
        }
    },
    "fas-fa-close": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-close']
        }
    },
    "fas-fa-file-medical": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-file-medical']
        }
    },
    "fas-fa-file": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-file']
        }
    },
    "fas-fa-taxi": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-taxi']
        }
    },
    "fas-fa-credit-card": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-credit-card']
        }
    },
    "fas-fa-user": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-user']
        }
    },
    "fas-fa-envelope": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-envelope']
        }
    },
    "fas-fa-user-secret": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-user-secret']
        }
    },
    "fas-fa-id-card": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-id-card']
        }
    },
    "fas-fa-sign-out-alt": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-sign-out-alt']
        }
    },
    "fas-fa-unlock-alt": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-unlock-alt']
        }
    },
    "far-fa-square-check": {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'fa-square-check']
        }
    },
    "far-fa-square": {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'fa-square']
        }
    },
    "far-fa-calendar-alt": {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'fa-calendar-alt']
        }
    },
    "fas-fa-star": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-star']
        }
    },
    "fas-fa-euro": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-euro']
        }
    },
    "fas-fa-note-sticky": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-note-sticky']
        }
    },
    "fas-fa-trash": {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fa-trash']
        }
    },
}
export { CUSTOM_ICONS }
