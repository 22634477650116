


































































































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import TransportationPermissionListItem from "@/components/TransportationPermissionListItem.vue";
import restService from "@/services/restService";

@Component({
  components: {
    TransportationPermissionListItem,
  },
})
export default class PassengerTransportationPermissions extends Vue {
  selectedPassenger: IPassenger | null = null;
  // passengerPermissions: IBookingQuotaWithPeriods[] = []
  dateWithoutTime: string = new Date().toISOString().substr(0, 10);

  selectedPermissionIndex: number = 0;
  currentStep: number = 1;

  @Watch("currentStep")
  onCurrenStepChanged(newVal: number, oldVal: number) {
    if (newVal == null || newVal === oldVal || oldVal == null) {
      return;
    }
    this.$nextTick(() => {
      if(this.passengers.length > 1){
        const stepTitleRef = this.$refs.stepTitleRef as HTMLElement;
        if (stepTitleRef) {
          stepTitleRef.focus();
        }
      }
    });
  }

  get steps(): any[] {
    let steps = [
      { id: 1, title: this.$t("transportationPermission.selectPassenger") },
    ];
   
    steps = steps.concat([
      {
        id: 2,
        title: this.selectedPassenger 
          ? this.selectedPassenger.name
          : this.$t("transportationPermission.selectPassenger") 
      }
    ]);
    return steps;
  }
 

  get currentStepNumber(): number {
    return this.steps.findIndex((s) => s.id === this.currentStep) + 1;
  }

  get currentStepTitle(): string {
    let step = this.steps.find((s) => s.id === this.currentStep);
    return step ? step.title : "TODO: StepTitleWhenNoStepFound";
  }

  nextStep(stepNumber: number) {
    switch (stepNumber) {
      case 1: {
        if (this.passengers.length === 0) {
          return;
        }
        if (this.passengers.length === 1) {
          // skip the step forwards
          this.selectedPassenger = this.passengers[0];
          stepNumber++;
        }
        break;
      }
      case 2: {
        if (!this.selectedPassenger) {
          return;
        }
        break;
      }
    }
    this.currentStep = stepNumber;
  }

  prevStep(stepNumber: number) {
    switch (stepNumber) {
      case 2: {
        if (this.passengers.length === 1) {
          // skip the step backwards
          stepNumber--;
        }
        break;
      }
    }
    this.currentStep = stepNumber;
  }

  @Watch("selectedPassenger")
  async passengerChanged() {
    if (this.selectedPassenger) {
      await this.$store.dispatch(
        "quota/getPassengerTransportationPermissions",
        this.selectedPassenger.id
      );
      // this.passengerPermissions = await restService.quotas.getWithPeriods(this.selectedPassenger.id);
    }
  }

  get passengerPermissions() {
    if (this.selectedPassenger) {
      return this.$store.getters["quota/permissionsForPassenger"](
        this.selectedPassenger.id
      );
    }
    return [];
  }

  get passengers(): IPassenger[] {
    return this.$store.getters["passenger/passengers"];
  }

  permissionSelected(permission: IBookingQuotaWithPeriods) {
    if (!this.selectedPassenger){
      return;
    }
    this.$router.push(
      {
        name: "transportationPermission",
        params: {
          passengerId: this.selectedPassenger.id.toString(),
          id: permission.id.toString()
        }
      }
    );
  }

  isObject(item: any) {
    return typeof item === "object";
  }

  reset() {
    this.currentStep = 1;
  }

  mounted() {
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
  }

  created(){
    if (this.passengers.length === 1){
      this.selectedPassenger = this.passengers[0];
      this.nextStep(2);
    }
  }
}
