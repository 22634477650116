if (!Array.prototype.indexById) {
    // Returns Movit models index in array by given Id or model
    Array.prototype.indexById = function(id: number | string | object) {
        if (!Array.isArray(this)) { throw new TypeError('this is null'); }
        if (typeof (id) === 'undefined') { return -1; }
        if (typeof (id) === 'object') {
            id = (id as any).id;
        }
        for (let i = 0; i < this.length; i++) {
            if (this[i].id === id) {
                return i;
            }
        }
        return -1;
    };
}

if (!Array.prototype.replaceOrAddById) {
    // Replaces Movit model in array if Id matches and returns the replaced index (-1 if not found)
    Array.prototype.replaceOrAddById = function(item: any) {
        if (!Array.isArray(this)) { throw new TypeError('this is null'); }
        const i = this.indexById(item);
        if (i >= 0) {
            this.splice(i, 1, item);
            return i;
        } else {
            this.push(item);
            return this.length - 1;
        }
    };
}
