import ThemeDefault from './default/theme_default';
import ThemeEspoo from './espoo/theme_espoo';
import ThemeKovanen from './kovanen/theme_kovanen';
import ThemeFixutaxi from './fixutaxi/theme_fixutaxi';
import ThemeMeneva from './meneva/theme_meneva';
import ITheme from './ITheme';
import ThemeHelpbus from './helpbus/theme_helpbus';
import ThemeTatKela from './tatkela/theme_tatkela';
import ThemeFcg from './fcg/theme_fcg';
import ThemeKulkukeskus from './kulkukeskus/theme_kulkukeskus';
import ThemeKeskustaksi from './keskustaksi/theme_keskustaksi';
import ThemeTh from './th/theme_th';
import ThemeFimlab from './fimlab/theme_fimlab';
import ThemeDemo from './demo/theme_demo';
import ThemeLahitaksi from './lahitaksi/theme_lahitaksi'
import ThemeLapha from './lapha/theme_lapha';

export default class Themes {

    static themeDefault =  new ThemeDefault();
    static themeEspoo = new ThemeEspoo();
    static themeKovanen = new ThemeKovanen();
    static themeFixutaxi = new ThemeFixutaxi();
    static themeMeneva = new ThemeMeneva();
    static themeHelpbus = new ThemeHelpbus();
    static themeTatKela = new ThemeTatKela();
    static themeFcg = new ThemeFcg();
    static themeKulkukeskus = new ThemeKulkukeskus();
    static themeKeskustaksi = new ThemeKeskustaksi();
    static themeTh = new ThemeTh();
    static themeFimlab = new ThemeFimlab();
    static themeDemo = new ThemeDemo();
    static themeLahitaksi = new ThemeLahitaksi();
    static themeLapha = new ThemeLapha();

    static themes: ITheme[] = [
      Themes.themeDefault,
      Themes.themeEspoo,
      Themes.themeKovanen,
      Themes.themeFixutaxi,
      Themes.themeMeneva,
      Themes.themeHelpbus,
      Themes.themeTatKela,
      Themes.themeFcg,
      Themes.themeKulkukeskus,
      Themes.themeKeskustaksi,
      Themes.themeTh,
      Themes.themeFimlab,
      Themes.themeDemo,
      Themes.themeLahitaksi,
      Themes.themeLapha,
    ];

    static get themeIds(): string[] {
        // ['default', 'kovanen', 'fixutaxi', 'espoo', 'meneva', 'helpbus', 'tatkela', 'fcg', 'kulkukeskus', 'keskustaksi', 'taksihelsinki', 'fimlab'];
        return this.themes.map(t => t.id);
    }

    static MatchTenantFromUrl(): string{
        if (document.location.host.indexOf('localhost') > -1 || document.location.host.indexOf('.ngrok.io') > -1) {
            const matchedTenantByPathname = Themes.themes.find(t => document.location.pathname.startsWith("/" + t.id));
            if (matchedTenantByPathname){
                return matchedTenantByPathname.id;
            }
            const devTheme = localStorage.getItem('themeId');
            if (typeof(devTheme) === 'string' && Themes.themes.some(t => t.id === devTheme)) {
                return devTheme;
            }
        }
        const matchedTenantByHostname = Themes.themes.find(t => t.matchIfHostNameContainsAny.some(m => document.location.host.indexOf(m) > -1));
        if (matchedTenantByHostname){
            return matchedTenantByHostname.id;
        }
        return 'default';
  }
}
