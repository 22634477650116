import restService from '@/services/restService';
import moment from 'moment-timezone';
import { Module } from 'vuex';
import { IRootState } from './store';
import { INotice } from './notice-store';

export interface ITripState {
  trips: ITrip[];
}

// STATE
const tripState: ITripState = {
    trips: [],
};

// GETTERS
const getters = {
  tripById: (state: ITripState) => (id: number, date: string) => {
    const trip = state.trips.find(t => {
     const tripDate = (t.startDateTime || t.endDateTime).split('T')[0];
     return t.id === id && tripDate === date;
    });
    return trip;
  },
  dates: (state: ITripState) => {
    return state.trips.map(t => (t.startDateTime || t.endDateTime).split('T')[0])
      .filter((d, i, a) => a.indexOf(d) === i);
  },
  trips: (state: ITripState) => (date: string, passenger?: IPassenger) => {
    const min = new Date(date);
    min.setHours(0, 0, 0, 0);
    const max = new Date(min);
    max.setDate(max.getDate() + 1);
    const tripsForDate = state.trips.filter(t => {
      const startTime = new Date(t.startDateTime || t.endDateTime).getTime();
      return min.getTime() <= startTime && startTime < max.getTime();
    });
    if (passenger) {
      return tripsForDate.filter(t => t.passenger.id === passenger.id);
    } else {
      return tripsForDate;
    }
  },
  nextTripForPassenger: (state: ITripState) => (passneger: IPassenger) => {
    const now = moment();
    const upcomingTrips = state.trips.filter(t => {
      if (t.passenger.id !== passneger.id) {
        return false;
      }
      const startTime = moment(t.startDateTime);
      return now < startTime;
    });
    if (upcomingTrips.length > 0) {
      return upcomingTrips[0];
    }
  },
};

// MUTATIONS
const mutations = {
  tripsSetInitial: (state: ITripState, trips: ITrip[]) => {
    state.trips = trips;
  },
  addOrReplaceTrip: (state: ITripState, trip: ITrip) => {
    const tripTime = moment(trip.startDateTime || trip.endDateTime).valueOf();
    const existing = state.trips.filter(t => t.id === trip.id)
      .filter(t => {
        const time = moment(t.startDateTime || t.endDateTime).valueOf();
        return time === tripTime;
      }).find(e => e);

    if (existing) {
      const index = state.trips.indexOf(existing);
      state.trips.splice(index, 1, trip);
    } else {
      state.trips.push(trip);
    }
    state.trips.sort((t1, t2) => {
      const d1 = new Date(t1.startDateTime || t1.endDateTime);
      const d2 = new Date(t2.startDateTime || t2.endDateTime);
      const sd = d1. getTime() - d2.getTime();
      if (sd === 0) {
        return t1.passenger.name.localeCompare(t2.passenger.name);
      } else {
        return sd;
      }
    });
  },
  updatePassenger: (state: ITripState, passenger: IPassenger) => {
    state.trips.forEach(t => {
      if (t.passenger.id === passenger.id) {
        t.passenger = passenger;
      }
    });
  },
  clearUserData:  (state: ITripState) => {
    state.trips = [];
  },
};

// ACTIONS
const actions = {
  async updateTrip({state, commit}: {state: ITripState, commit: any}, trip: ITrip) {
    const tripDate = (trip.startDateTime || trip.endDateTime).split('T')[0];
    const existing = state.trips.filter(t => t.id === trip.id)
      .filter(t => {
        const date = (t.startDateTime || t.endDateTime).split('T')[0];
        return date === tripDate;
      })[0];

    if (existing) {
      const link = `/trips/${trip.id}/${tripDate}`;
      const notice: INotice = {
        text: '',
        button: 'notice.open_trip',
        link,
      };
      if (!existing.pickupTime && trip.pickupTime) {
        notice.text = 'notice.passenger_picked_up';
        commit('notice/showNotice', notice , { root: true });
      }
      if (!existing.dropoffTime && trip.dropoffTime) {
        notice.text = 'notice.passenger_dropped_of';
        commit('notice/showNotice', notice, { root: true });
      }
    }

    commit('addOrReplaceTrip', trip);
  },
  async cancelTrips({commit}: {commit: any}, cancellation: ITripCancellation) {
    try {
      const updatedTrips = await restService.trips.cancel(cancellation);
      updatedTrips.forEach(t => commit('addOrReplaceTrip', t));
      // Remove trip caches from effected passengers
      const passengers = updatedTrips.map(t => t.passenger.id)
        .filter((p, i, a) => a.indexOf(p) === i);
      const apicache = await caches.open('api');
      for (const id of passengers) {
        const cachePath = `/api/passenger/${id}/trips`;
        await apicache.delete(cachePath);
      }
      return updatedTrips;
    } 
    catch(error) {
      commit('app/error', (error as any).message || error, { root: true });
      throw error;
    }
  },
  async reviewTrip({commit, state}: {commit: any, state: ITripState}, review: ITripReview) {
    const result = await restService.trips.review(review);
    // TODO: What to do in case of errror?
    const trip = state.trips.filter(t => t.id === review.trip.id)[0];
    if (trip.reviews) {
      trip.reviews.push(review);
    } else {
      const reviews = new Array();
      reviews.push(review);
      trip.reviews = reviews;
    }
    commit('addOrReplaceTrip', trip);
  },
  async addTrackingTrip({ commit }: { commit: any }, link: string) {
    const trip = await restService.trips.tracked(link);
    commit('addOrReplaceTrip', trip);
    return trip;
  },

};

const tripStore: Module<ITripState, IRootState> = {
  namespaced: true,
  state: tripState,
  mutations,
  actions,
  getters,
};

export default tripStore;
