






























































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PlacePickerG from "@/components/PlacePickerG.vue";
import TimePicker from "@/components/TimePicker.vue";
import BookingStep from "./models/BookingStep";
import restService from "@/services/restService";
import { TranslateResult } from "vue-i18n";

enum ETripDestinations {
  Home_MainPlace,
  PlaceGroups,
  PassengerPlaces,
  FreePlaces,
  Home_Secondary,
  MainPlace_Secondary,
}

@Component({
  components: {
    PlacePickerG,
    TimePicker,
  },
})
export default class BookingStepSelectTimeAndAddresses extends Vue {
  @Prop()
  order!: IBookingOrder;
  @Prop()
  rules!: any;
  @Prop({ default: () => [] })
  private passengerDestinations!: IDestination[];
  @Prop({default: true}) 
  hasPrevStep!: boolean;
  @Prop({default: null})
  dateAndTime!: string | null;

  @Watch("dateAndTime", { immediate: true})
  dateAndTimeDateChanged(val: string | null) {
    if (!val){
      this.timeWithoutDate = this.getDefaultTimeWithoutDate();
    }
    this.timeWithoutDate = this.$moment(this.order.dateAndTime)
      .diff(
        this.$moment().startOf("day"),
        "minutes"
      );
  }

  timeWithoutDate: string = this.getDefaultTimeWithoutDate();
  departureTimeOptions: any[] = BookingStep.departureTimeOptions;
  selectedDepartureTimeOptionValue: any = BookingStep.departureTimeOptions[0].value;
  viaPointToOrder: boolean = false;
  bookingRouteDetails: IComparableRoute | null = null;
  waitTimes = [0, 5, 10];
  viaPointsAreValid: boolean = false;
  viaPointStatusMessage: TranslateResult | string = "";

  getDefaultTimeWithoutDate(){
    const lastMinuteOfDay = this.$moment().endOf('day');
    return this.$moment.max(
        this.$moment().add(5, 'minutes'), 
        lastMinuteOfDay
      )
      .diff(
        this.$moment().startOf("day"),
        "minutes"
      );
  }

  created() {
    (window as any).__b3 = this;
    this.$on("placeChange", () => {
      setTimeout(async () => {
        // this.viaPointsAreValid
        const viaPointRef = this.$refs.viaPointPlaceInputRef as PlacePickerG[];
        if (viaPointRef) {
          this.viaPointsAreValid = viaPointRef.every((r) => r.isValid);
        } else {
          this.viaPointsAreValid = true;
        }
        const t = this.order.viaPoints;
        if (this.orderHasAllAddresses) {
          this.bookingRouteDetails = await this.getRouteDetails();
        }
      }, 0);
    });
  }

  async getRouteDetails() {
    let destinations: IDestination[] = [];
    if (this.order.viaPoints.length > 0) {
      destinations = this.order.viaPoints
        .filter((v) => v.viaPointAddress)
        .map((v) => v.viaPointAddress!);
    }
    destinations.push(this.order.toAddress!);
    destinations.unshift(this.order.fromAddress!);
    const result = await restService.booking.getRoute(destinations);
    return result;
  }

  get availablePassengerPlaces(): IDestination[] {
    
    if(this.order.quota) {
      return this.filterDestinationByQuota(this.passengerDestinations, this.order.quota);
    }
    return this.passengerDestinations;
  }

  get availablePassengerDestinationPlaces(): IDestination[] {
    // Filter out already used destinations
    return this.availablePassengerPlaces.filter(
      (pd) => pd.id !== this.order.fromAddress?.id
    );
  }

  get stepIsValid(){
    const requireValids = [
      this.order.fromAddress ? true : false,
      this.order.toAddress ? true : false,
      this.$refs.timePicker && (this.$refs.timePicker as TimePicker).isValid,
      this.$refs.originPlaceInputRef && (this.$refs.originPlaceInputRef as PlacePickerG).isValid,
      this.$refs.destinationPlaceInputRef && (this.$refs.destinationPlaceInputRef as PlacePickerG).isValid,
      this.viaPointsAreValid || !this.order.viaPoints || this.order.viaPoints.length === 0,
      !this.viaPointDeviationExceedsMax
    ];
    return requireValids.every(v => v);
  }

  get isLikumaPermission() {
    if (this.order.quota) {
      return (
        this.order.quota.decisionType === "VPL" ||
        this.order.quota.decisionType === "SHL"
      );
    }
    return false;
  }

  get allowAllPlaces() {
    if (this.order.quota) {
      return this.order.quota.tripDestinations === ETripDestinations.FreePlaces;
    }
    return false;
  }


  // get viaPointsAreValid() {
  //   const startPlace = this.order.fromAddress;
  //   const vias = this.order.viaPoints.map(v => v);
  //   const endPlace = this.order.toAddress;
  //   if(this.order.viaPoints.length > 0) {
  //     const test = this.order.viaPoints.every((v, i) => (this.$refs['viaPointPlaceInputRef' + i] as any).isValid);
  //     return this.order.viaPoints.every((v, i) => (this.$refs['viaPointPlaceInputRef' + i] as any).isValid);
  //   }
  //   return true;
  // }

  get orderHasAllAddresses() {
    return (
      !!this.order.fromAddress &&
      this.order.viaPoints.every((v) => !!v.viaPointAddress) &&
      !!this.order.toAddress
    );
  }

  get viaPointsEnabled() {
    return this.order.quota?.allowViaPoints;
  }

  get viaPointMaxDeviation(): number | null {
    if (this.viaPointsEnabled) {
      if (this.order.quota?.viaPointMaxDeviation) {
        return this.order.quota?.viaPointMaxDeviation / 1000;
      }
      return null;
    }
    return null;
  }

  get viaPointDeviation(): number {
    if (
      this.bookingRouteDetails &&
      this.bookingRouteDetails.routeWithViaPointDistance &&
      this.bookingRouteDetails.routeWithViaPointDuration
    ) {
      let difference =
        this.bookingRouteDetails.routeWithViaPointDistance -
        this.bookingRouteDetails.originalRouteDistance;
      difference = Math.round((difference / 1000) * 100) / 100;
      return difference;
    }
    return 0;
  }

  get viaPointDeviationExceedsMax() {
    if (!this.viaPointsEnabled) {
      return false;
    }

    if (this.viaPointsEnabled && !this.viaPointMaxDeviation) {
      return false;
    }
    return this.viaPointDeviation > this.viaPointMaxDeviation!;
  }

  // get fromAddressApartment() {
  //   console.log("get fromAddressApartment()")
  //   return this.order.fromAddress
  //     ? this.order.fromAddress.apartment as string
  //     : '';
  // }
  // set fromAddressApartment(val: string) {
  //   console.log("set fromAddressApartment, val:", val)
  //   if (this.order.fromAddress) {
  //     this.order.fromAddress.apartment = val;
  //   }
  // }
  // get toAddressApartment() {
  //   console.log("get toAddressApartment()")
  //   return this.order.toAddress
  //     ? this.order.toAddress.apartment as string
  //     : '';
  // }
  // set toAddressApartment(val: string) {
  //   console.log("set toAddressApartment, val:", val)
  //   if (this.order.toAddress) {
  //     this.order.toAddress.apartment = val;
  //   }
  // }

  filterDestinationByQuota(destinations: IDestination[], quota: IBookingQuota): IDestination[] {
    switch (quota.tripDestinations) {
      case ETripDestinations.Home_MainPlace:
            return destinations.filter(d => d.isHome || d.isPickup || d.isMain);
        case ETripDestinations.Home_Secondary:
            return destinations.filter(d => d.isHome || d.isPickup || d.isSecondaryPlace);
        case ETripDestinations.MainPlace_Secondary:
          return destinations.filter(d => d.isMain || d.isSecondaryPlace);
        default:
            return destinations;
    }
}

  addViaPoint() {
    const newViaPoint: IViaPoint = {
      viaPointPlace: "",
      viaPointAddress: null,
      viaPointApartment: null,
      waitTime: 10
    };
    this.order.viaPoints.push(newViaPoint);
    this.viaPointStatusMessage = "";
  }

  removeViaPoint(viaPoint: IViaPoint) {
    const index = this.order.viaPoints.indexOf(viaPoint);
    this.order.viaPoints.splice(index, 1);
    this.bookingRouteDetails = null;
    this.viaPointStatusMessage = this.$t("booking.viaPoint.removed");

    const t = this;
    window.setTimeout(() => {
      const addViaPointButtonRef = t.$refs.addViaPointButtonRef as Vue;
      if (addViaPointButtonRef){
        const addViaPointButtonElem = addViaPointButtonRef.$el as HTMLElement;
        if (addViaPointButtonElem){
          addViaPointButtonElem.focus();
        } 
      } 
    }, 120);

     window.setTimeout(() => {
      t.viaPointStatusMessage = "";
    }, 2000);
  }

  onIsDepartureTimeChanged(optionValue: any) {
    this.order.isDepartureTime = optionValue;
  }

  onOriginPlaceChange(event: any) {
    this.$emit("placeChange");
  }

  onViaPointChange(event: any) {
    this.$emit("placeChange");
  }

  onDestinationPlaceChange(event: any) {
    this.$emit("placeChange");
  }

  onOriginPlaceSelected(event: any) {
    // TODO: Do we need any of the below
    // this.$emit("placeChange");
    // (this.$refs.destinationPlaceInputRef as any).focus();
  }

  onDestinationPlaceSelected(event: any) {
    // TODO: Do we need any of the below
    // this.$emit("placeChange");
    // (this.$refs.step2SubmitButtonRef as any).$el.focus();
  }

  timeChangedInPicked(value: number) {
    const hours = value / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const updatedTime = this.$moment(this.order.dateAndTime);
    updatedTime.set({ hours: rhours, minutes: rminutes });
    this.order.dateAndTime = updatedTime;
  }

}
