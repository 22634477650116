/* tslint:disable:no-console */
import { register } from 'register-service-worker';

if ('serviceWorker' in navigator) {
  if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}sw.js`, {
      ready() {
        // TODO
      },
      cached() {
        // TODO
      },
      updated() {
        // TODO
      },
      offline() {
        // TODO
      },
      error(error) {
        console.error('Error during service worker registration:', error);
      },
    });
  }
}
