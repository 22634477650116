import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { VuetifyFontAwesomeIconsConfig } from '@/plugins/vuetify-fontawesome'
import i18n from '@/i18n';

Vue.use(Vuetify);
export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params).toString(),
  },
  icons: VuetifyFontAwesomeIconsConfig,
  theme: {
    themes: {
      light: {
        primary: '#099',
        success: '#1f890e',
        warning: '#FFC107',
      },
    },
  },
});
