import './theme_default.scss';
import ITheme from '../ITheme';
import NavigationItems from '@/navigationItems';

export default class ThemeDefault implements ITheme {
    id: string = 'default';
    matchIfHostNameContainsAny: string[] = ["localhost", ".ngrok.io", "127.0.0.1"];
    name: string = 'Kouluun.fi';
    cssClassName: string = 'theme_default';
    dark: boolean = false;
    menuDark: boolean = false;
    toolbarDark: boolean = true;
    bottomNavDark: boolean = false;
    menuRight: boolean = false;
    booking: boolean = false;
    menuNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.personalInfo,
        NavigationItems.messages,
        NavigationItems.accessibilityReport,
        NavigationItems.privacy,
    ];
    bottomNavNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.messages,
    ];
    vuetifyTheme: any = {
        primary: '#099',
        warning: '#FFC107',
        // accent: '#ffffff',
        bottomNavColor: '#ffffff',
    };
}
