
if (!Date.prototype.toISODate) {
    // Returns a date that match UTC midnight at the same date
    Date.prototype.toISODate = function() {
        if (this === null) { throw new TypeError('this is null'); }
        const y = this.getFullYear();
        const m = this.getMonth() + 1;
        const d = this.getDate();
        return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
    };
}
