import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import MapHelpers from './mapHelpers';
import PlaceMarkerFeature, { EPlaceMarkerType } from './placeMarkerFeature';

export default class PlaceLayer extends VectorLayer<VectorSource<Geometry>> {
  constructor(trip: ITrip, options: any = { className: 'mapPlaces' }) {
    super(options);

    let startFeature: PlaceMarkerFeature | null = null;
    const startCoordinates = MapHelpers.getCoordinateFromAddress(trip.startAddress);
    if (startCoordinates) {
      startFeature = new PlaceMarkerFeature(startCoordinates, EPlaceMarkerType.Start)
      startFeature.setId('startPlace');
    }

    let viaPointFeatures: PlaceMarkerFeature[] = [];
    if(trip.viaPoints.length > 0) {
      const viaPointCoordinates = trip.viaPoints.map(v => MapHelpers.getCoordinateFromAddress(v.viaPointAddress))
      .filter(a => a);

      viaPointFeatures = viaPointCoordinates.map((c, i) => {
        const placeMarker = new PlaceMarkerFeature(c!, EPlaceMarkerType.ViaPoint);
        placeMarker.setId('viaPoint' + i);
        return placeMarker;
      });
    }
    

    let endFeature: PlaceMarkerFeature | null = null;
    const endCoordinates = MapHelpers.getCoordinateFromAddress(trip.endAddress);
    if (endCoordinates) {
      endFeature = new PlaceMarkerFeature(endCoordinates, EPlaceMarkerType.End);
      endFeature.setId('endPlace')
    }

    const features: PlaceMarkerFeature[] = [];
    if(startFeature) {
      features.unshift(startFeature);
    }
    
    viaPointFeatures.forEach(v => features.push(v));

    if(endFeature) {
      features.push(endFeature);
    }

    this.setSource(new VectorSource({ features }));
  }
}
