



























import {Vue, Prop, Component, Watch} from 'vue-property-decorator';
import ReservationPeriodListItem from '@/components/ReservationPeriodListItem.vue';
import moment from 'moment';
@Component({
    components: {
      ReservationPeriodListItem
    }
})
export default class PassengerTransportationPermission extends Vue {
  
  @Prop({ required: true })
  permission!: IBookingQuotaWithPeriods;

  activePeriodIndex = 0;

  closeDialog() {
    this.$emit("close");
  }

  @Watch("permission", { immediate: true })
  passengerChanged(newVal: IPassenger, oldVal: IPassenger) {
    if (newVal) {
      this.setActivePeriod();
    }
  }

  @Prop({ required: true, default: false })
  dialogOpen!: boolean;
  @Watch("dialogOpen", { immediate: true })
  dialogOpenChanged(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.setActivePeriod();
    } else {
      // Do nothing?
    }
  }

  // async created(){
  //   const id = parseInt(this.$route.params.id, 10);
  //   this.permission = this.$store.getters['quota/permissionById'](id);
  //   if (!this.permission){
  //     // If permission was not found, reload passenger
  //     const passengerId = this.$route.params.passengerId;
  //      await this.$store.dispatch(
  //         "quota/getPassengerTransportationPermissions",
  //         passengerId
  //     );
  //   }
  //   this.permission = this.$store.getters['quota/permissionById'](id);
  //   this.setActivePeriod();
  // }


  get startDate() {
    if (this.permission) {
        return this.$moment(this.permission.startDate).format('DD.MM.YYYY');
    } 
    return '';
  }

  get endDate() {
    if (this.permission) {
        return this.$moment(this.permission.endDate).format('DD.MM.YYYY');
    } 
    return '';
  }

  setActivePeriod() {
    if(this.permission) {
      const today = moment();
      today.set({hour:0,minute:0,second:0,millisecond:0});


      if(this.permission.periods.length < 2) {
        this.activePeriodIndex = 0;
        return;
      }

      const activePeriod = this.permission?.periods.find(p => {
        const start = moment(p.startDate); 
        const end = moment(p.endDate); 
        return today >= start && today <= end;
      });
  
      if(activePeriod) {
        this.activePeriodIndex = this.permission?.periods.indexOf(activePeriod);
      }
    }
  }

}
