


















































































import {Component, Prop, Watch, Vue } from 'vue-property-decorator';
import restService from '../services/restService';
@Component({
  components: {

  },
})
export default class RegisterTravelCard extends Vue {
  loading: boolean = false;
  notFound: boolean = false;
  failed: boolean = false;
  name: string = '';
  cardNumber: string = '';
  invitation: IUserInvitation | null = null;

  get isInvited() {
    return this.invitation ? true : false;
  }

  get isAuthenticated() {
    return this.$store.getters['auth/isAuthenticated'];
  }

  async sendRegistration() {
    this.loading = true;
    this.notFound = false;
    this.failed = false;

    try {
      this.invitation =  await restService.auth.registerTravelCard(this.cardNumber, this.name);
    } catch (error) {
      this.invitation = null;
      // TODO: Strong type error
      if ((error as any).status === 404) {
        this.notFound = true;
      } else {
        this.failed = true;
      }
    } finally {
      this.loading = false;
    }
  }

  async acceptAsCurrentUser() {
    if (this.invitation && this.isAuthenticated) {
      try {
        await this.$store.dispatch('passenger/acceptInvitation', this.invitation);
        this.$router.push('/passengers');
      } catch (error) {
        // TODO: Strong type error
        if ((error as any).status === 404) {
          this.$store.commit('app/error', this.$t('invitation.not_found').toString());
        }
      }
    }
  }

  async acceptAndLogOut() {
     if (this.invitation) {
      if (this.isAuthenticated) {
        window.location.href = '/logoutandacceptinvite?code=' + this.invitation.code + '&locale=' + this.$i18n.locale;
      } else {
        window.location.href = '/acceptinvite?code=' + this.invitation.code + '&locale=' + this.$i18n.locale;
      }
    }
  }


}

