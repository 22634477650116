

































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import restService from '../services/restService';

@Component
export default class Invitation extends Vue {

  loading: boolean = true;
  notFound: boolean = false;
  bruteForce: boolean = false;
  invitation: IUserInvitation | null = null;

  get isAuthenticated() {
    return this.$store.getters['auth/isAuthenticated'];
  }

  created() {
    this.onRouteChanged();
  }

  @Watch('$route')
  async onRouteChanged() {
    if (this.$route.params.code) {
      this.loading = true;
      this.notFound = false;
      this.bruteForce = false;
      try {
        this.invitation = await restService.invitation.get(this.$route.params.code);
      } catch (error) {
        // TODO: Strong type error
        this.invitation = null;
        if ((error as any).status === 404) {
          this.notFound = true;
        } else if ((error as any).status === 429) {
          this.bruteForce = true;
        }
      }
      this.loading = false;
    }
  }

  async acceptInvitation() {
    if (this.invitation) {
      try {
        await this.$store.dispatch('passenger/acceptInvitation', this.invitation);

      } catch (error) {
        // TODO: Strong type error
        if ((error as any).status === 404) {
          // TODO: show error message
          this.$store.commit('app/error', this.$t('invitation.not_found').toString());
        }
      }
      // Reload invite data
      this.onRouteChanged();
    }
  }

  changeUser() {
    if (this.invitation) {
      if (this.isAuthenticated) {
        window.location.href = '/logoutandacceptinvite?code=' + this.invitation.code + '&locale=' + this.$i18n.locale;
      } else {
        window.location.href = '/acceptinvite?code=' + this.invitation.code + '&locale=' + this.$i18n.locale;
      }
    }
  }

  goBack() {
    this.$router.go(-1);
  }
}
