























import { Vue, Component, Prop } from 'vue-property-decorator'
import VueSkipToSingle from './VueSkipToSingle.vue'

@Component({
  name: "VueSkipToList",
  components: {
    VueSkipToSingle
  }
})
export default class VueSkipToList extends Vue {

  @Prop({default: 'Skip to'})
  listLabel!: string;

  @Prop({
    default: () => [], 
    validator: (val) => {
      return Array.isArray(val) &&
        val.every(({ anchor, label }) => (
          typeof anchor === 'string' &&
            anchor.indexOf('#') === 0 &&
            typeof String(label) === 'string'
        ))
    }
  })
  to!: Array<{ anchor: string, label: string, ariaLabel: string }>;

  handleKeydown ({key, target}: { key: string, target: HTMLElement }) {
    const parent = target.parentElement
    if (!parent){ 
      return;
    }

    const itemList = /(ArrowUp|Up)/g.test(key) 
      ? parent.previousElementSibling 
      : parent.nextElementSibling

    if (!itemList){ 
      return;
    }

    const link = itemList.getElementsByTagName('a')
    if (link.length) { 
      link[0].focus()
    } 
  }
}
