import { TranslateResult } from "vue-i18n";

export default class BookingStep {
    id: number = 0;
    type: BookingStepTypeEnum = BookingStepTypeEnum.Unknown;
    title!: TranslateResult;

    static departureTimeOptions = [
        { label: 'booking.departure', value: true },
        { label: 'booking.arrival', value: false },
    ];
}

export class BookingStepLoading {
    passengers = false;
    quotas = false;
}

export enum BookingStepTypeEnum {
    Unknown = 0,
    SelectPassenger = 1,
    SelectDate = 2,
    SelectQuota = 3,
    SelectTimeAndAddresses = 4,
    EnterAdditionalInfo = 5,
    ViewOrderSummary = 6,
}