var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{attrs:{"cols":12,"sm":12}},[_c('v-row',[(_vm.loading.quotas)?[_c('v-col',{staticClass:"text-subtitle-1",attrs:{"cols":12}},[_c('div',[_vm._v(_vm._s(_vm.$t("booking.fetchingQuotas")))])]),_c('v-col',{attrs:{"cols":12}},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","rounded":"","height":"6"}})],1)]:[(_vm.items.length > 0)?_c('fieldset',{staticClass:"no-border"},[_c('v-radio-group',{staticClass:"mt-0 a11y-radios",staticStyle:{"flex-grow":"1"},attrs:{"label":_vm.$t('booking.quotaName') + '*',"aria-label":_vm.$t('booking.quotaName'),"required":true,"aria-required":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{attrs:{"aria-hidden":"true","id":"quotaNameLabel"}},[_vm._v(_vm._s(_vm.$t('booking.quotaName'))+"*")])]},proxy:true}],null,false,1531914091),model:{value:(_vm.order.quota),callback:function ($$v) {_vm.$set(_vm.order, "quota", $$v)},expression:"order.quota"}},_vm._l((_vm.items),function(quota){return _c('v-radio',{key:quota.name,attrs:{"color":"blue darken-3 white--text","value":quota,"aria-label":'Matkaoikeus: ' +
                quota.name +
                ', ' +
                _vm.$t('booking.tripsLeft') +
                ': ' +
                quota.remainingQuota +
                (
                  quota.note 
                  ? ' ' + quota.note
                  : ''
                ),"aria-describedby":"quotaNameLabel"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-list-item-title',{staticClass:"\n                      primary--text\n                      d-flex\n                      justify-space-between\n                      flex-wrap-reverse\n                    ",attrs:{"aria-hidden":"true"}},[_c('span',[_vm._v(" "+_vm._s(quota.name)+" ")]),_c('v-spacer'),_c('QuotaChip',{staticClass:"mx-2",attrs:{"quota":quota.remainingQuota,"text":_vm.$t('booking.tripsLeft'),"small":""}}),(quota.expressQuotaTimeLimit > 0)?_c('QuotaChip',{staticClass:"mx-2",attrs:{"quota":quota.remainingExpressQuota,"text":_vm.$t('transportationPermission.expressQuotas'),"small":""}}):_vm._e(),(quota.decisionType)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(quota.decisionType)+" ")]):_vm._e()],1),_c('v-list-item-subtitle',{attrs:{"aria-hidden":"true"}},[_vm._v(" "+_vm._s(quota.note)+" ")])],1)]},proxy:true}],null,true)})}),1)],1):[_c('v-alert',{attrs:{"type":"error","value":true,"aria-live":"polite"}},[_c('div',[_vm._v(_vm._s(_vm.$t("booking.noQuotas")))]),_c('div',[_vm._v(_vm._s(_vm.$t("booking.noQuotasHelp")))])])]]],2),_c('v-row',{staticClass:"mb-0"},[_c('v-btn',{attrs:{"large":"","color":"primary","disabled":!_vm.order.quota || _vm.loading.quotas},on:{"click":function($event){return _vm.$emit('nextClick')}}},[_vm._v(" "+_vm._s(_vm.$t("general.button.next_step"))+" ")]),(_vm.hasPrevStep)?_c('v-btn',{staticClass:"ml-2",attrs:{"large":""},on:{"click":function($event){return _vm.$emit('prevClick')}}},[_vm._v(" "+_vm._s(_vm.$t("general.button.back"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }