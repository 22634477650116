








































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PersonalInfo extends Vue {

  get userHasPassword() {
    return this.$store.getters['app/userHasPassword'];
  }

  get name() {
    return this.$store.state.app.user.name;
  }

  get username() {
    return this.$store.state.app.user.userName;
  }

  get email() {
    return this.$store.state.app.user.email || '';
  }

  get phone() {
    return this.$store.state.app.user.phone || '';
  }
  mounted() {
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
  }
}
