import { Module } from 'vuex';
import tokenService from '@/services/tokenService';
import restService, { RideitBackend } from '@/services/restService';
import router from '@/router';
import { IRootState } from './store';
import i18n from '@/i18n';

export interface IAuthState {
    accessToken?: string;
    refreshTimeout?: number;
}

const authState: IAuthState = {
    accessToken: localStorage.getItem('access_token') || '',
    refreshTimeout: undefined,
};

const authGetters = {
    isAuthenticated: (state: IAuthState, getters: any) => !!getters.accessToken,
    accessToken: (state: IAuthState) => {
        // Check if access token exists and if it is still valid
        if (state.accessToken) {
            const currentTime = new Date().getTime() / 1000;
            const token = tokenService.decodeToken(state.accessToken);
            if (token && token.exp && token.exp > currentTime) {
                return state.accessToken;
            }
        }
        return '';
    },
};

const authMutations = {
    setAccessToken: (state: IAuthState, token: string) => {
        localStorage.setItem('access_token', token);
        state.accessToken = token;
    },
    authSuccess: (state: IAuthState, token: string) => {
        state.accessToken = token;
    },
    authError: (state: IAuthState) => {
        localStorage.removeItem('access_token');
        state.accessToken = undefined;
    },
    authLogout: (state: IAuthState) => {
        localStorage.removeItem('access_token');
        state.accessToken = undefined;
    },
};

const authActions = {
    logout: async ({commit, dispatch}: {commit: any, dispatch: any}) => {
        commit('authLogout');
        await dispatch('app/clearUserData', null, { root: true });

        const url = RideitBackend.baseUrl + 'logout?locale=' + i18n.locale;
        window.location.href = url;
    },
    authenticationExpired: async ({commit, dispatch, rootState}: {commit: any, dispatch: any, rootState: any}) => {
        if (navigator.onLine) {
            commit('authLogout');
            await dispatch('app/clearUserData', null, { root: true });
            if (rootState.route.meta.requireAuthentication) {
                const currentPath = rootState.route.fullPath;
                localStorage.setItem('redirect', currentPath);
                router.push({
                    name: 'home',
                    query: { redirect: currentPath },
                });
            }
        }
    },
    updateRefreshTokenTimeout: ({commit, state, dispatch}: {commit: any, state: IAuthState, dispatch: any}) => {
        if (state.refreshTimeout) {
            clearTimeout(state.refreshTimeout);
            state.refreshTimeout = undefined;
        }
        if (state.accessToken) {
            const expiresIn = tokenService.expiresIn(state.accessToken);
            if (expiresIn > 0) {
                state.refreshTimeout = window.setTimeout(() => {
                    dispatch('refreshAccessToken');
                }, (expiresIn - 30) * 1000);
            } else {
                // Token has expired
                dispatch('authenticationExpired');
            }
        }
    },
    refreshAccessToken: async ({commit, dispatch, getters}: {commit: any, dispatch: any, getters: any}) => {
        try {
            const wasLoggedIn = getters.isAuthenticated;
            const tokenData = await restService.auth.getAccessToken();
            if (tokenData && tokenData.accessToken) {
                commit('setAccessToken', tokenData.accessToken);
                dispatch('updateRefreshTokenTimeout');
                if (!wasLoggedIn) {
                    const redirect = localStorage.getItem('redirect');
                    if (redirect) {
                        localStorage.removeItem('redirect');
                        router.push({ path: redirect });
                    } else {
                        commit('app/navigateToLandingPage', null, {root: true});
                    }
                }
            } else {
                dispatch('authenticationExpired');
            }
        } catch (error) {
            // Should not happen
            // dispatch('authenticationExpired');
            // console.error(error);
        }
    },
};

const authStore: Module<IAuthState, IRootState> = {
    namespaced: true,
    state: authState,
    getters: authGetters,
    actions: authActions,
    mutations: authMutations,
};
export default authStore;
