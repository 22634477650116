import Vue from "vue";

export default class MenuHelper {
    public static menuTabPressed(event: KeyboardEvent, component: Vue){
        const eventTarget = event.target as any;
        if (!eventTarget){
            return;
        }
        let menu = component.$refs[eventTarget.dataset.menu] as any;
        if (!menu){
          return;
        }
        // make sure children are evaluated
        menu.getTiles();
        // In most cases, we want the event to be stopped,
        // however, in the cases where we tab 'out of' the menu,
        // we want to propagate the event.
        let propagate = false;
    
        if (menu.isActive) {
            if (event.shiftKey) {
              if (menu.listIndex <= 0) {
                menu.isActive = false;
                propagate = true;
              } else {
                menu.prevTile();
              }
            } else {
              if (menu.listIndex < 0) {
                menu.firstTile();
              } else if (menu.listIndex + 1 >= menu.tiles.length) {
                menu.isActive = false;
                propagate = true;
              } else {
                menu.nextTile()
              }
            }
        } else {
          propagate = true;
        }
        if (!propagate) {
          event.preventDefault();
        }
    }
}