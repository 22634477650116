

























import { Component, Vue, Prop } from 'vue-property-decorator';
import restService from '@/services/restService';

@Component
export default class MessageItem extends Vue {

  @Prop() message!: IMessage;
  @Prop({ default: true }) showPassengerChip!: boolean;

  private timer?: number;

  get passenger(): IPassenger {
    return this.$store.getters['passenger/passengerById'](this.message.passengerId);
  }

  get content(): string {
    return this.$sanitize(this.message.body.replace(/\n/g, '<br/>'));
  }

  get ownMessage() {
    const username = this.$store.state.app.user.name;
    return this.message.from === username;
  }

  mounted() {
    if (!this.message.seenAt && !this.ownMessage) {
      this.timer = window.setTimeout(() => {
          this.markAsSeen();
          this.timer = undefined;
      }, 4000);
    }
  }

  destroyed() {
      if (this.timer) {
          window.clearTimeout(this.timer);
          this.timer = undefined;
      }
  }

  markAsSeen() {
    if (!this.ownMessage) {
      this.$store.dispatch('message/markAsSeen', this.message);
    }
  }

  markAsConfirmed() {
    if (!this.ownMessage) {
      this.$store.dispatch('message/markAsConfirmed', this.message);
    }
  }
}
