import { Module } from 'vuex';
import restService from '@/services/restService';
import { IRootState } from './store';

export interface IMessageState {
    messages: IMessage[];
    firstPage?: number;
    totalPages: number;
}

const messageSorter = (a: IMessage, b: IMessage) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

// STATE
const messageState: IMessageState = {
    messages: [],
    firstPage: undefined,
    totalPages: 0,
};

// GETTERS
const getters = {
    userName: (state: IMessageState, getter: any, rootState: any) => {
      return (rootState.app.user || { name: '' }).name;
    },
    sentByUser: (state: IMessageState, getter: any) => {
      const username = getter.userName;
      return state.messages.filter(m => m.from === username);
    },
    unseenCount: (state: IMessageState, getter: any, rootState: any) => {
      const username = getter.userName;
      return state.messages.filter(m => !m.seenAt && m.to === username).length;
    },
    forPassenger: (state: IMessageState) => (passengerId: string) => {
      return state.messages.filter(m => m.passengerId === passengerId);
    },
    canLoadMore: (state: IMessageState) => {
      return state.firstPage !== 1;
    },
};

// MUTATIONS
const mutations = {
  addMessages: (state: IMessageState, messagePage: IPagedMessages) => {
    messagePage.items.forEach(m => state.messages.replaceOrAddById(m));
    state.messages.sort(messageSorter);
    state.totalPages = messagePage.totalPages;
    state.firstPage = Math.min(messagePage.page, (state.firstPage || Number.MAX_SAFE_INTEGER));
  },
  updateMessage: (state: IMessageState, message: IMessage) => {
    state.messages.replaceOrAddById(message);
    state.messages.sort(messageSorter);
  },
  clearUserData: (state: IMessageState) => {
    state.messages = [];
  },
};

// ACTIONS
const actions = {
  initializeMessages: async ({dispatch, commit}: {dispatch: any, commit: any}) => {
    const messages = await restService.message.get();
    commit('addMessages', messages);
    // If last page contains less than 10 messages, load prev page also
    if (messages.items.length < 10 && messages.hasPreviousPage) {
      await dispatch('loadPreviousPage');
    }
  },
  loadLastPage: async ({commit}: {commit: any}) => {
    const messages = await restService.message.get();
    commit('addMessages', messages);
    return messages;
  },
  loadPreviousPage: async ({state, commit}: {state: IMessageState, commit: any}) => {
    if (typeof(state.firstPage) === 'number' && state.firstPage > 1) {
      const messages = await restService.message.get(state.firstPage - 1);
      commit('addMessages', messages);
      return messages;
    }
  },
  sendMessage: async ({commit}: {commit: any}, message: IMessage) => {
    const result = await restService.message.send(message.passengerId, message.body);
    commit('updateMessage', result);
  },
  markAsSeen: async ({commit}: {commit: any}, message: IMessage) => {
    const result = await restService.message.markSeen(message);
    commit('updateMessage', result);
  },
  markAsConfirmed: async ({commit}: {commit: any}, message: IMessage) => {
    const result = await restService.message.markConfirmed(message);
    commit('updateMessage', result);
  },
};

const appStore: Module<IMessageState, IRootState> = {
  namespaced: true,
  state: messageState,
  mutations,
  actions,
  getters,
};
export default appStore;
