












































import { Component, Prop, Vue } from "vue-property-decorator";
import PassengerEdit from "./PassengerEdit.vue";

@Component({
  components: {
    PassengerEdit,
  },
})
export default class PassengerListItem extends Vue {
  @Prop({ default: null }) passenger!: IPassenger;

  editPassengerDialog: boolean = false;

  editPassenger(passenger: IPassenger) {
    // workaround for screen readers to retain focus
    // unfortunate side effect is that we have a zillion dialog instances laying around
    this.editPassengerDialog = true;
    // this.$router.push('/passengers/' + passenger.id);
  }

  get nextTrip() {
    const nextTrip = this.$store.getters["trip/nextTripForPassenger"](
      this.passenger
    );
    if (nextTrip) {
      return nextTrip.startDateTime || nextTrip.endDateTime;
    }
  }
  onClosePassengerDialog(result: {saveSuccess: boolean, saveError: boolean}) {
    this.editPassengerDialog = false;
    this.$emit("editDialogClose", result);
  }
  
}
