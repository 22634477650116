import Vue from 'vue';
import moment from 'moment';
import i18n from './i18n';

/** Global filters for Vue. Mostly localization date formats */

Vue.filter('dateTime', (date: Date | string) => {
  if (!date) { return ''; }
  return moment(date).format('L HH:mm');
});

Vue.filter('dateTimeOrNA', (date: Date | string) => {
  if (!date) { 
    return i18n.t('general.time_na');
  }
  return moment(date).format('L HH:mm');
});

Vue.filter('date', (date: Date | string) => {
  if (!date) { return ''; }
  return moment(date).format('L');
});

Vue.filter('time', (date: Date | string) => {
  if (!date) {
    return i18n.t('general.time_na');
  }
  return moment(date).format('HH:mm');
});

Vue.filter('emptyField', (content: string) => {
  if (!content) {
    return i18n.t('general.empty_field');
  }
  return content;
});
