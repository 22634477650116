import '@/helpers/arrayExtensions';
import Vue from 'vue';
import AppRoot from './layouts/app-root.vue';
import i18n from './i18n';
import router from './router';
import store from './store/store';
import applicationInsights from './applicationInsights';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import { sync } from 'vuex-router-sync';
// import 'babel-polyfill';
import './sanitizer';
import ColorHelper from './helpers/ColorHelper';
import './filters';
import './registerServiceWorker';
import './sass/main.scss';
import '@/extansions';
import tokenService from './services/tokenService';
import GoogleMaps from '@/plugins/googlemaps/index';

Vue.config.productionTip = false;

Vue.prototype.$randomColor = ColorHelper.getRandomColorWithSeed;

axios.interceptors.request.use((config: any) => {
  const authToken = store.getters['auth/accessToken'];
  if (authToken) {
    const tokenData = tokenService.decodeToken(authToken);
    if (tokenData && tokenData.exp) {
      const expiration = Math.round(tokenData.exp - (new Date().getTime() / 1000));
      // console.log(`access_token expires in ${expiration} seconds [${new Date(tokenData.exp * 1000).toISOString()}]`);
    }
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
}, (err: any) => {
  return Promise.reject(err);
});

axios.interceptors.response.use((response: any) => {
  return response;
}, (err: any) => {
  if (err.response && err.response.status === 401) {
    store.dispatch('auth/authenticationExpired');
  }
  return Promise.reject(err.response);
});

sync(store, router);

Vue.use(applicationInsights, {router});

Vue.use(new GoogleMaps(), {
   apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
   // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
   // version: '...', // Optional
   language: 'fi', // Optional
});

const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(AppRoot),
  created: () => {
    const html = document.documentElement;
    html.setAttribute('lang', i18n.locale);
  },
});

app.$mount('#app');
export default app;

