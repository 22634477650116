





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class PhoneNumber extends Vue {

  get phoneNumbers() {
    let numbers = '';
    const user = this.$store.state.app.user;
    if (user.phoneNumber) {
      numbers += user.phoneNumber + ', ';
    }

    if (this.passenger && this.passenger.phone) {
      numbers += this.passenger.phone;
    }
    const phoneNumbers = numbers.split(/[\s,]+/)
      .filter(n => n)
      .map((n: string ) => n.replace(/^0/, '+358') as string);

    return phoneNumbers;
 }

 get passenger() {
   if (this.passengerId) {
     return this.$store.getters['passenger/passengerById'](this.passengerId);
   }
 }

 get isValid() {
   return !(this.$refs.phoneNumRef as any).hasError;
 }

  selectedNumber: string = this.phoneNumbers.length > 0 ? this.phoneNumbers[0] : '+358';

  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) ariaLabel!: string;
  @Prop({ default: '' }) private passengerId!: string;
  @Prop({ default: null }) rules!: any[];
  @Prop({ default: '' }) private value!: string;
  @Prop({ default: false }) autofocus!: boolean;

  @Watch('value', { immediate: true }) onValueChanged() {
    if ( this.selectedNumber !== this.value) {
      this.selectedNumber = this.value ? this.value : this.phoneNumbers.length > 0 ? this.phoneNumbers[0] : '+358';
    }
  }

  @Watch('selectedNumber', { immediate: true }) onSelectedNumberChanged() {
    this.selectedNumber = this.selectedNumber ? this.selectedNumber.replace(/^0/, '+358') : '';
    this.$emit('input', this.selectedNumber);
  }

}
