import { render, staticRenderFns } from "./PhoneNumber.vue?vue&type=template&id=970a30d6&scoped=true&"
import script from "./PhoneNumber.vue?vue&type=script&lang=ts&"
export * from "./PhoneNumber.vue?vue&type=script&lang=ts&"
import style0 from "./PhoneNumber.vue?vue&type=style&index=0&id=970a30d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "970a30d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VCombobox})
