
















































import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class ReservationPeriodListItem extends Vue {
  @Prop() period!: IReservationPeriod;

  today = moment();

  get periodStart() {
    if (this.period) {
      return this.$moment(this.period.startDate).format("DD.MM.YYYY");
    }
    return "";
  }

  get periodEnd() {
    if (this.period) {
      return this.$moment(this.period.endDate).format("DD.MM.YYYY");
    }
    return "";
  }

  get isActive(): boolean {
    const today = this.today.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const start = moment(this.period.startDate);
    const end = moment(this.period.endDate);

    return today >= start && today <= end;
  }
}
