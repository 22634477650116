









































































































































































































import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BookingStepViewOrderSummary extends Vue {
  @Prop() 
  order!: IBookingOrder;
  @Prop() 
  booking!: IBooking;
  @Prop({default: false}) 
  private saving!: boolean;
  @Prop({default: false}) 
  saved!: boolean;
  @Prop({default: true}) 
  hasPrevStep!: boolean;
  @Prop({default: true}) 
  enableCreateReturnBooking!: boolean;
  
  get passengerName() {
    return this.passenger ? this.passenger.name : '';
  }

  get passenger() {
    if (this.booking && this.booking.passengers && this.booking.passengers.length > 0) {
      return this.booking.passengers[0];
    }
  }

  get storePassenger() {
    if (this.passenger) {
      return this.$store.getters['passenger/passengerById'](this.passenger.id) as IPassenger;
    }
  }

 get quota() {
    return this.order.quota;
  }

  get expressQuotaTimeLimit() {
    return this.quota?.expressQuotaTimeLimit ? this.quota.expressQuotaTimeLimit : 0;
  }

  get remainingExpressQuotaAmount() {
    return this.quota ? this.quota?.remainingExpressQuota : 0;

  }

  get shouldShowExpressQuotaWarning() {
    if(this.expressQuotaTimeLimit > 0) {
      const now = moment();
      const departureDateTime = moment(this.booking.departureDateTime);
      const diff = departureDateTime.diff(now, 'minutes');
      return diff < this.expressQuotaTimeLimit;
    }
    return false;
  }


  get date() {
    if (this.booking) {
      if (this.booking.departureDateTime) {
        return this.$moment(this.booking.departureDateTime).format('DD.MM.YYYY');
      } else {
        return this.$moment(this.booking.arrivalDateTime).format('DD.MM.YYYY');
      }
    }
    return '';
  }

  get time() {
    if (this.booking) {
      if (this.booking.departureDateTime) {
        const time = this.$moment(this.booking.departureDateTime).format('HH:mm') ;
        const departureOrArrival = this.$t('booking.departure');
        return `${departureOrArrival} : ${time}`;
      } else {
        const time = this.$moment(this.booking.arrivalDateTime).format('HH:mm') ;
        const departureOrArrival = this.$t('booking.arrival');
        return `${departureOrArrival} : ${time}`;
      }
    }
    return '';
  }

  get startPlace() {
    if(this.booking) {
      return `${this.getAddressString(this.booking.destinations[0])}`;
    }
  }

  get endPlace() {
    if(this.booking) {
      return `${this.getAddressString(this.booking.destinations[this.booking.destinations.length - 1])}`;
    }
  }

  get totalPassengers() {
    return this.booking.passengers ? this.booking.passengers.length : 0;
  }

  get orderPrice() {
    return this.booking ? this.booking.price : 0.00;
  }

  get saveDisabled() {
    if (this.shouldShowExpressQuotaWarning) {
      return !(!this.saving
        && !!this.booking.destinations[0] && !!this.booking.destinations[1]
        && (!!this.booking.departureDateTime || !!this.booking.arrivalDateTime))
        || this.remainingExpressQuotaAmount < 1;
    } else {
      return !(!this.saving
        // && !!this.booking.quota
        && !!this.booking.destinations[0] && !!this.booking.destinations[1]
        && (!!this.booking.departureDateTime || !!this.booking.arrivalDateTime));
    }
  }

  

  getAddressString(destination: IDestination) {
    if (destination && !destination.address) {
          return destination.alias;
        }
        let address = destination.address;
        address += destination.city ? `, ${destination.city}` : ``;
        return  address;
  }

  saveOrder() {
    this.$emit('saveClick');
  }

}
