import { Module } from 'vuex';
import { IRootState } from './store';

export interface INoticeState {
  show: boolean;
  text: string;
  color?: string;
  button: string;
  link: string;
  queue: INotice[];
}

export interface INotice {
  text: string;
  color?: string;
  button: string;
  link: string;
}

// STATE
const messageState: INoticeState = {
  show: false,
  text: '',
  color: undefined,
  button: '',
  link: '',
  queue: [],
};

// GETTERS
const getters = {
};

// MUTATIONS
const mutations = {
  showNotice: (state: INoticeState, notice: INotice) => {
    if (state.queue.length === 0) {
      state.text = notice.text;
      state.color = notice.color;
      state.link = notice.link;
      state.button = notice.button;
      state.show = true;
    } else {
      state.queue.push(notice);
    }
  },
  hideNotice: (state: INoticeState) => {
    state.show = false;
  },
  showNextNotice: (state: INoticeState) => {
    const next = state.queue.shift();
    if (next) {
      state.text = next.text;
      state.color = next.color;
      state.link = next.link;
      state.button = next.button;
      state.show = true;
    }
  },
  clearUserData: (state: INoticeState) => {
    state.queue = [];
    state.text = '';
    state.color = undefined;
    state.button = '';
    state.link = '';
  },
};

// ACTIONS
const actions = {

};

const appStore: Module<INoticeState, IRootState> = {
  namespaced: true,
  state: messageState,
  mutations,
  actions,
  getters,
};
export default appStore;
