



























































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BookingStepSelectPassenger extends Vue {
  @Prop()
  private value!: IPassenger;

  get editedPassenger(): IPassenger | null {
    return this.value;
  }
  set editedPassenger(val) {
    this.$emit("change", val);
  }

  get passengers(): IPassenger[] {
    return this.$store.getters["passenger/passengers"];
  }

  // TODO: on mounted/created?
  //  if (passengers.length === 1) {
  //     this.passenger = passengers[0];
  //   }

  isObject(item: any) {
    return typeof item === "object";
  }

  created(){
    if (this.passengers.length >= 1){
      this.editedPassenger = this.passengers[0];
    }
  }
}
