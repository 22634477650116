import { fromLonLat } from 'ol/proj';

export default class MapHelpers {
  public static getCoordinateFromAddress(address: IAddress) {
    if (address && address.lat && address.lng) {
      const latitude = typeof address.lat === 'string' ? parseFloat(address.lat) : address.lat;
      const longitude = typeof address.lng === 'string' ? parseFloat(address.lng) : address.lng;
      return fromLonLat([longitude, latitude]);
    }
    return null;
  }

  public static getCoordinateFromCarLocation(carLocation: ICarLocation) {
    if (carLocation) {
      return fromLonLat([carLocation.longitude, carLocation.latitude]);
    }
    return null;
  }
}
