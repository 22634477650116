import Vue from 'vue';
import VueI18n, { I18nOptions, TranslateResult, Path } from 'vue-i18n';
import messages from './locale';
import store from './store/store';

// Declare $tt method for translating theme specific translations
Vue.prototype.$tt = function(key: Path, ...values: any): TranslateResult {
  const themeId = this.$store.state.app.themeId;
  const vueI18n = this.$i18n;

  const exists = vueI18n._te(key + '.' + themeId, vueI18n.locale, vueI18n._getMessages(), ...values);
  if (exists) {
    key = key + '.' + themeId;
  }
  return vueI18n._t(key, vueI18n.locale, vueI18n._getMessages(), this, ...values);
};

// Create VueI18n instance with options
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.getters['app/locale'], // set locale
  fallbackLocale: 'fi',
  messages,
  silentTranslationWarn: true,
});

export default i18n;
