
















































































import moment, { Moment } from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import restService from '../services/restService';

@Component({
  components: {
  },
})
export default class AccessibilityReportKeusote extends Vue {
  
  onExternalLinkClicked(e: any) {
    const message = this.$t('general.external_link_confirm') as string;
    var confirmed = window.confirm(message);
    if (!confirmed){
      e.preventDefault();
      e.stopPropagation();
    }
  }

  mounted() {
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
  }

}
