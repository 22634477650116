




import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import 'ol/ol.css';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import MapHelpers from './mapHelpers';
import PlaceLayer from './placeLayer';
import CarLayer from './carLayer';
import { extend } from 'ol/extent';

@Component
export default class TripMap extends Vue {

  map: Map | null = null 
  carLayer: CarLayer | null = null;
  // placeLayer: PlaceLayer | null = null;

  @Prop({ required: true }) trip!: ITrip;
  @Prop({ default: () => null }) carLocation!: ICarLocation | null;


  @Watch('carLocation', { immediate: true }) onCarLocationChanged() {
    if (!this.carLocation) {
      this.carLayer?.setVisible(false);
      return;
    }

    const coordinates = MapHelpers.getCoordinateFromCarLocation(this.carLocation);
    if (!coordinates) {
      return;
    }
    
    if (!this.carLayer) {
      this.carLayer = new CarLayer(coordinates);
      this.map?.addLayer(this.carLayer);

      const startPlaceExtent = this.placeLayer?.getSource()?.getFeatureById('startPlace')?.getGeometry()?.getExtent();
      const carExtent = this.carLayer.getSource()?.getFeatureById('carFeature')?.getGeometry()?.getExtent();

      if (startPlaceExtent && carExtent) {
        const combinedExtent = extend(startPlaceExtent, carExtent);
        this.map?.getView().fit(combinedExtent, { duration: 300, padding: [ 50, 50, 50, 50 ]});
      }
    } 
    if (this.carLayer.getVisible() === false) {
      this.carLayer.setVisible(true);
    }

    this.carLayer.updateCoordinates(coordinates);
    
  }

  get center() {
    let coordinates = [0, 0];

    if (this.trip.startAddress) {
      const startCoordinates = MapHelpers.getCoordinateFromAddress(this.trip.startAddress);
      coordinates = startCoordinates ?? coordinates;
    } else if (this.trip.endAddress) {
      const endCoordinates = MapHelpers.getCoordinateFromAddress(this.trip.endAddress);
      coordinates = endCoordinates ?? coordinates;
    }

    return coordinates;
  }

  get placeLayer(): PlaceLayer | null {
    if (this.trip) {
      return new PlaceLayer(this.trip);
    }
    return null;
  }

  mounted() {
    const tileLayer = new TileLayer({ source: new OSM() }); 
    const placeLayer = new PlaceLayer(this.trip);

    const layers = [ tileLayer, placeLayer ];
    
    if (this.carLayer) {
      layers.push(this.carLayer);
    }

    this.map = new Map({
      target: 'map',
      layers,
      view: new View({
        center: this.center,
        zoom: 15,
        maxZoom: 17,
        minZoom: 10,
      })
    });
    // Hide interactive elements inside map from screen readers (#5927)
    let mapElem = this.$refs.mapRef as HTMLElement;
    window.setTimeout(() => {
      const interactives = mapElem.querySelectorAll("button, a");
      for(let interactive of interactives){
        interactive.setAttribute("tabindex", "-1");
      }
    }, 200);
   
  }
}
