import moment from "moment";
import Vue from "vue";

export default class DateHelper {

    public static isoDateToFinnishDate(val: string): string {
      return moment(val).format('D.M.YYYY')
    }

    public static isIsoDate(val: string): boolean {
      const valParts = val.split("-");
      if (valParts.length != 3){
        return false;
      }
      const year = valParts[0];
      const month = valParts[1];
      const dayOfMonth = valParts[2];
      return DateHelper.isValidYMD(year, month, dayOfMonth);
    }

    public static finnishDateToIsoDate(val: string): string | null {
      let valParts, year, month, dayOfMonth;
      if (DateHelper.isIsoDate(val)){
        valParts = val.split("-");
        year = valParts[0];
        month = valParts[1];
        dayOfMonth = valParts[2];
      } else {
        valParts = val.split(".");
        if (valParts.length < 3){
          console.warn("DateHelper:", "Not enough date components:", val);
          return null;
        }
        year = valParts[2];
        month = valParts[1];
        dayOfMonth = valParts[0];
        if (!DateHelper.isValidYMD(year, month, dayOfMonth)){
          return null;
        }
      }
      return year + "-" + month.padStart(2, "0") + "-" + dayOfMonth.padStart(2, "0");
    }

    public static isValidYMD(year: string, month: string, dayOfMonth: string): boolean {
      return DateHelper.isValidYear(year) && DateHelper.isValidMonth(month) && DateHelper.isValidDayOfMonth(dayOfMonth);
    }

    public static isValidYear(yearString: string){
      const year: number = DateHelper.toNumber(yearString);
      if (isNaN(year) || year < 1900 || year > 2999){
        console.warn("DateHelper:", "Year is invalid:", year);
        return false;
      }
      return true;
    }
    public static isValidMonth(monthString: string){
      const month: number = DateHelper.toNumber(monthString);
      if (isNaN(month) || month < 1 || month > 12){
        console.warn("DateHelper:", "Month is invalid:", month);
        return false;
      }
      return true;
    }
    public static isValidDayOfMonth(dayOfMonthString: string){
      const dayOfMonth: number = DateHelper.toNumber(dayOfMonthString);
      if (isNaN(dayOfMonth) || dayOfMonth < 1 || dayOfMonth > 31){
        console.warn("DateHelper:", "DayOfMonth is invalid:", dayOfMonth);
        return false;
      }
      return true;
    }

    public static toNumber(val: string): number {
      const isNumber = /^\d+$/.test(val);
      return isNumber ? parseInt(val): NaN; 
    }
}