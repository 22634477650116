













import { Vue, Component, Prop } from 'vue-property-decorator'
// import { programmaticFocus, focusedTags } from './util'

@Component({
  name: "VueSkipToSingle"
})
export default class VueSkipToSingle extends Vue {

  @Prop({default: 'Skip to main content'})
  label!: string;

  @Prop({default: '#main'})
  to!: string;

  // handleFocusElement (event: Event) {
  //   const target = event.target as HTMLElement;
  //   if (!target){
  //     return;
  //   }
  //   const href = target.getAttribute('href');
  //   if (!href){
  //     return;
  //   }
  //   this.focusElement(href.substring(1))
  // }

  // focusElement (id: string) {
  //   if (!id) {
  //     return;
  //   }
  //   const element = window.document.getElementById(id)
  //   if (!element) {
  //     return;
  //   }
  //   if (!focusedTags.test(element.tagName.toLowerCase())){
  //     this.$router.replace({ hash: this.to });
  //     return programmaticFocus(element);
  //   }
  //   element.focus();
  //}

}
