import './theme_th.scss';
import ITheme from '../ITheme';
import NavigationItems from '@/navigationItems';

export default class ThemeTh implements ITheme {
    id: string = 'th';
    matchIfHostNameContainsAny: string[] = ["th"];
    name: string = 'th';
    cssClassName: string = 'theme_th';
    dark: boolean = false;
    menuDark: boolean = false;
    toolbarDark: boolean = true;
    bottomNavDark: boolean = true;
    menuRight: boolean = false;
    booking: boolean = true;
    menuNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.booking,
        NavigationItems.personalInfo,
        NavigationItems.messages,
        NavigationItems.accessibilityReport,
        NavigationItems.privacy,
    ];
    bottomNavNavigationItems: INavigationItem[] = [
        NavigationItems.booking,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.messages,
    ];
    vuetifyTheme: any = {
        primary: '#050505',
        secondary: '#f9cf0c',
        accent: '#f9cf0c',
        warning: '#f9cf0c',
        bottomNavColor: '#f9cf0c',
    };
}
