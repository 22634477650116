import restService from '@/services/restService';


interface ITransportationPermissionsState {
  permissions: IBookingQuotaWithPeriods[];
}

// STATE
const state: ITransportationPermissionsState = {
    permissions: [],
};

// GETTERS
const getters = {
  permissionsForPassenger: (state: ITransportationPermissionsState) => (id: string) =>
  state.permissions.filter(p => p.passenger === id),
  permissionById: (state: ITransportationPermissionsState) => (id: number) =>
  state.permissions.find(p => p.id === id),

};

// MUTATIONS
const mutations = {
  setPermissions: (state: ITransportationPermissionsState, permissions: IBookingQuotaWithPeriods[]) => state.permissions = permissions,
};

const actions = {
  getPassengerTransportationPermissions : async ({commit}: {commit: any}, passengerId: string) => {
    const permissions = await restService.quotas.getWithPeriods(passengerId);
    commit('setPermissions', permissions);
  }
};

const quotaStore  = { state, mutations, actions, getters };
(quotaStore as any).namespaced = true;
export default quotaStore;
