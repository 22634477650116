import './theme_helpbus.scss';
import ITheme from '../ITheme';
import NavigationItems from '@/navigationItems';

export default class ThemeHelpbus implements ITheme {
    id: string =  'helpbus';
    matchIfHostNameContainsAny: string[]  = ['helpbus'];
    name: string = 'Helpbus';
    cssClassName: string = 'theme_helpbus';
    dark: boolean = false;
    menuDark: boolean = false;
    toolbarDark: boolean = true;
    bottomNavDark: boolean = false;
    menuRight: boolean = false;
    booking: boolean = false;
    menuNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.personalInfo,
        NavigationItems.messages,
        NavigationItems.accessibilityReport,
        NavigationItems.privacy,
    ];
    bottomNavNavigationItems: INavigationItem[] = [
        NavigationItems.passengers,
        NavigationItems.trips,
        NavigationItems.cancelTrips,
        NavigationItems.messages,
    ];
    vuetifyTheme: any = {
        primary: '#333333',
        warning: '#FFC107',
        // accent: '#ffffff',
        bottomNavColor: '#ffffff',
    };
}
