




















































































































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import DateHelper from '@/helpers/DateHelper';

@Component({
  components: {},
})
export default class AccessibleTextInputDatePicker extends Vue {

  @Prop({ default: () => [] })
  errors!: any[];

  @Prop({ default: '' })
  value!: string;

  @Prop({ default: () => new Date().toISOString().substr(0, 10)})
  minDate!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: '' })
  ariaLabel!: string;

  @Prop({ default: '' })
  label!: string;

  uid: string = (Math.random() * 10e16).toString(16);

  dayChosen: boolean = false;

  get ariaLabelPrefix(): string {
    if (!this.ariaLabel && !this.ariaLabel.trim()){
        return '';
    }
    return this.ariaLabel + ", ";
  }

  get labelPrefix(): string {
    if (!this.label && !this.label.trim()){
        return '';
    }
    return this.label + ", ";
  }

  @Watch('value')
  valueChanged(newVal: string, oldVal: string) {
    if (newVal === oldVal || !newVal){
      return;
    }
    const newDate = new Date(newVal);
    this.editedYear = newDate.getFullYear();
    this.editedMonth = newDate.getMonth() + 1;
    this.editedDay = newDate.getDate();
  }
 
  // TODO: Remove when not needed anymore
  startDateOpen: boolean = false;

  todayDate: Date = new Date();
  editedYearValid = true;
  editedMonthValid = true;
  editedDayValid = true;
  get valid(){
    return this.editedYearValid && this.editedMonthValid && this.editedDayValid;
  }
  @Watch('valid')
  validChanged(newVal: boolean, oldVal: boolean){
    this.$emit("validChange", newVal);
  }

  editedYear: number = this.currentYear;
  @Watch('editedYear')
  editedYearChanged(newVal: string, oldVal: string) {
    if (!newVal){
      this.editedYearValid = false;
      return;
    }
    if(newVal?.toString() === oldVal?.toString()){
      // No change, don't do anything, just return
      return;
    }
    // console.log("editedYearChanged", newVal, oldVal)
    const newValNumber = parseInt(newVal, 10);
    if (!this.isValidYear(newValNumber)){
      // New value is not valid
      const oldValNumber = parseInt(oldVal, 10);
      if (!this.isValidYear(oldValNumber)){
        // Old value is not valid either, restore editedYear to currentMonth
        this.$nextTick(() => this.editedYear = this.currentYear);
        return;
      } 
      // Old value is valid, restore editedYear to oldValue
      this.$nextTick(() => this.editedYear = oldValNumber);
      return;
    }
    this.editedYearValid = true;
    let date: Date = new Date(this.dateWithoutTime);
    if (date.getFullYear() === newValNumber){
      // No change to original model, don't do anything, just return
      return;
    }
    date.setFullYear(newValNumber);

    // Check if we are below minimum, if so, set date to minimum
    const minDateMoment = moment(this.minDate);
    if (moment(date).isBefore(minDateMoment)){
      // console.log("Date is before minDate, setting date to minDate");
      this.$nextTick(() => {
        this.editedYear = minDateMoment.year();
        this.editedMonth = minDateMoment.month() + 1;
        this.editedDay = minDateMoment.date();
      });
      return;
    }

    // console.log("  editedYearChanged", "setting new value")
    this.dateWithoutTime = date.toISOString().substr(0, 10);
    this.$nextTick(() => {
      if (this.$refs.yearFieldRef){
        (this.$refs.yearFieldRef as HTMLElement).blur();
      }
      this.$nextTick(() => {
        if (this.$refs.monthFieldRef){
          (this.$refs.monthFieldRef as HTMLElement).focus();
        }
      });
    });
  }

  editedMonth: number = this.currentMonth;
  @Watch('editedMonth')
  editedMonthChanged(newVal: string, oldVal: string) {
    if (!newVal){
      this.editedMonthValid = false;
      return;
    }
     if (newVal?.toString() === oldVal?.toString()){
      // No change, don't do anything, just return
      return;
    }
    // console.log("editedMonthChanged", newVal, oldVal)
    const newValNumber = parseInt(newVal, 10);
    if (!this.isValidMonth(newValNumber)){
      // New value is not valid
      const oldValNumber = parseInt(oldVal, 10);
      if (!this.isValidMonth(oldValNumber)){
        // Old value is not valid either, restore editedMonth to currentMonth
        this.$nextTick(() => this.editedMonth = this.currentMonth);
        return;
      } 
      // Old value is valid, restore editedMonth to oldValue
       this.$nextTick(() => this.editedMonth = oldValNumber);
      return;
    }
    this.editedMonthValid = true;
    let date: Date = new Date(this.dateWithoutTime);
    if (date.getMonth() === newValNumber - 1){
      // No change to original model, don't do anything, just return
      return;
    }
    date.setMonth(newValNumber - 1);

    // Check if we are below minimum, if so, set date to minimum
    const minDateMoment = moment(this.minDate);
    if (moment(date).isBefore(minDateMoment)){
      // console.log("Date is before minDate, setting date to minDate");
      this.$nextTick(() => {
        this.editedYear = minDateMoment.year();
        this.editedMonth = minDateMoment.month() + 1;
        this.editedDay = minDateMoment.date();
      });
      return;
    }

    // console.log("  editedMonthChanged", "setting new value");
    this.dateWithoutTime = date.toISOString().substr(0, 10);
    this.$nextTick(() => {
      if (this.$refs.monthFieldRef){
        (this.$refs.monthFieldRef as HTMLElement).blur();
      }
      this.$nextTick(() => {
        if (this.$refs.dayFieldRef){
          (this.$refs.dayFieldRef as HTMLElement).focus();
        }
      });
    });
  }

  months = [...Array(12).keys()]
    .map(x => x + 1)
    .map(x => {
      return {
        value: x,
        name: moment(x, 'M').format('MMMM')
      }
    });

  editedMonthName: string = moment(this.currentMonth, "M").format("MMMM");
  @Watch('editedMonthName')
  editedMonthNameChanged(newVal: string, oldVal: string) {
    const newMonth = this.months.find(x => x.name === newVal);
    if (newMonth && newMonth.name !== oldVal){
      this.editedMonth = newMonth.value;
    }
  }

  editedDay: number = this.currentDay;
  @Watch('editedDay')
  editedDayChanged(newVal: string, oldVal: string) {
    if (!newVal){
      this.editedDayValid = false;
      return;
    }
    if (newVal?.toString() === oldVal?.toString()){
      // No change, don't do anything, just return
      return;
    }
    // console.log("editedDayChanged", newVal, oldVal)
    const newValNumber = parseInt(newVal, 10);
    if (!this.isValidDay(newValNumber)){
      // New value is not valid
      const oldValNumber = parseInt(oldVal, 10);
      if (!this.isValidDay(oldValNumber)){
        // Old value is not valid either, restore editedDay to currentMonth
        this.$nextTick(() => this.editedDay = this.currentDay);
        return;
      } 
      // Old value is valid, restore editedDay to oldValue
      this.$nextTick(() => this.editedDay = oldValNumber);
      return;
    }
    this.editedDayValid = true;
    let date: Date = new Date(this.dateWithoutTime)
    if (date.getDate() === newValNumber){
      // No change to original model, don't do anything, just return
      return;
    }
    date.setDate(newValNumber);

    // Check if we are below minimum, if so, set date to minimum
    const minDateMoment = moment(this.minDate);
    if (moment(date).isBefore(minDateMoment)){
      // console.log("Date is before minDate, setting date to minDate");
      this.$nextTick(() => {
        this.editedYear = minDateMoment.year();
        this.editedMonth = minDateMoment.month() + 1;
        this.editedDay = minDateMoment.date();
      });
      return;
    }

    // console.log("  editedDayChanged", "setting new value")
    this.dateWithoutTime = date.toISOString().substr(0, 10);
    this.dayChosen = true;
    this.$nextTick(() => {
      if (this.$refs.dayFieldRef){
        (this.$refs.dayFieldRef as HTMLElement).blur();
      }
      this.$nextTick(() => {
        if (this.$refs.dayChosenIndicatorRef){
          (this.$refs.dayChosenIndicatorRef as HTMLElement).focus();
        }
      });
    });

  }

  isValidYear(val: number){
    return val && !isNaN(val) && val >= 1000 && val <= 9999;
  }
  isValidMonth(val: number){
    return val && !isNaN(val) && val >= 1 && val <= 12;
  }
  isValidDay(val: number){
    const daysInMonth = moment().year(this.editedYear).month(this.editedMonth).daysInMonth();
    return val && !isNaN(val) && val >= 1 && val <= daysInMonth;
  }

  get dateWithoutTime(): string{
    return this.value;
  }
  set dateWithoutTime(val: string){
    // console.log("EMIT dateWithoutTime:", val);
    this.$emit("change", val);
  }

  get dateWithoutTimeLocaleFormatted(): string {
      return DateHelper.isoDateToFinnishDate(this.dateWithoutTime);
  }
  
  get yearOptions(){
    return [this.currentYear, this.currentYear + 1];
  }

  get monthOptions(){
    if (this.editedYear === this.currentYear){
      return this.months.filter(x => x.value >= this.currentMonth);
    }
    return this.months;
  }

  get monthNameOptions(){
    return this.monthOptions.map(x => x.name);
  }

  get dayOptions(){
    if (!this.editedYear || !this.editedMonth){
      return [];
    }

    const daysInMonth = moment().year(this.editedYear).month(this.editedMonth - 1).daysInMonth();
    const dayNumbers = [...Array(daysInMonth).keys()].map(x => x + 1);
    if (this.editedYear === this.currentYear && this.editedMonth === this.currentMonth){
      return dayNumbers.filter(x => x >= this.currentDay);
    }   
    return dayNumbers; 
  }

  get currentYear(){
    return this.todayDate.getFullYear();
  }

  get currentMonth(){
    return this.todayDate.getMonth() + 1;
  }
  
  get currentDay(){
    return this.todayDate.getDate();
  }

  get locale() {
    return this.$store.getters['app/locale'];
  }

  mounted(){
    this.todayDate = new Date();
  }

}
