








































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LocaleListItemGroup from "../components/LocaleListItemGroup.vue";
import NoticeBar from "../components/NoticeBar.vue";
import { TranslateResult } from "vue-i18n";
import ITheme from "../themes/ITheme";
import { IAppState } from "../store/app-store";
import Themes from "../themes";
import Vuetify from "vuetify";
import NavigationItems from "@/navigationItems";
import MenuHelper from "@/helpers/MenuHelper";
import { Route } from "vue-router";
import VueSkipTo from "@/plugins/@vue-a11y/VueSkipTo.vue"

@Component({
  components: {
    LocaleListItemGroup,
    NoticeBar,
    VueSkipTo
  },
})
export default class AppRoot extends Vue {
  drawer = false;
  isLoading: boolean = true;
  color: string = "primary";
  themeToggleEnabled: boolean = false;
  mainMenuStatusText: string | TranslateResult = "";

  @Watch("drawer")
  drawerChanged(val: boolean){
    let drawerStateIndicatorElem = document.getElementById('main-menu-title') as HTMLElement;
    if (drawerStateIndicatorElem){
      drawerStateIndicatorElem.setAttribute("aria-hidden", "false");
    }
    if (val){
      this.mainMenuStatusText = this.$t("general.main_menu_title_open");
      // Keep focus in the drawer now that it's open
      window.addEventListener('focusin', this.onDrawerFocusin);
    } else {
      this.mainMenuStatusText = this.$t("general.main_menu_title_closed");
      // Stop keeping focus in the drawer now that it's closed
      window.removeEventListener('focusin', this.onDrawerFocusin);
    }
    if (drawerStateIndicatorElem){
      window.setTimeout(() =>  {
        drawerStateIndicatorElem.setAttribute("aria-hidden", "true");
      }, 1000);
    }
  }

  onDrawerFocusin (e: Event) {
    if (!e){
      return;
    } 
    const ref = (this.$refs.drawerRef as Vue);
    if (!ref){
      return;
    }
    const drawerElem = ref.$el as HTMLElement;
    const target = e.target as HTMLElement;
    if (
      !!target &&
      drawerElem &&
      // It isn't the document or the drawer body
      ![document, drawerElem].includes(target) &&
      // It isn't inside the drawer body
      !drawerElem.contains(target) // &&
      // We're the topmost drawer
      // this.activeZIndex >= this.getMaxZIndex() &&
      // It isn't inside a dependent element (like a menu)
      // !this.getOpenDependentElements().some(el => el.contains(target))
    ) {
      // We must have focused something outside the drawer (or its children)
      // Find and focus the first available element inside the dialog
      const focusable = drawerElem.querySelectorAll(
        'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      const el = [...focusable].find(el => !el.hasAttribute('disabled') && !el.matches('[tabindex="-1"]')) as HTMLElement | undefined
      el && el.focus()
    }
  }

  get appBarHeight() {
    if (this.themeId !== "default") {
      return 56;
    }
  }

  get showTopNav() {
    return this.themeId !== "default";
  }

  get showBottomNav() {
    return this.themeId === "default";
  }

  get bookingEnabled() {
    return this.theme.booking;
  }

  get appState(): IAppState {
    return this.$store.state.app;
  }

  get themes(): ITheme[] {
    return this.appState.themes;
  }

  get theme(): ITheme {
    return this.$store.getters["app/theme"];
  }

  get themeId() {
    return this.appState.themeId;
  }

  get hasLogo() {
    return !!this.themeLogo;
  }

  get themeLogo() {
    switch (this.themeId) {
      case "kovanen":
        return require("../themes/kovanen/kovanen-logo-valkoinen-260.png");
      case "fixutaxi":
        return require("../themes/fixutaxi/logo.png");
      case "meneva":
        return require("../themes/meneva/logo.svg");
      case "espoo":
        return require("../themes/espoo/espoo_logo.png");
      case "fcg":
        return require("../themes/fcg/fcg_title_valkoinen.png");
      case "kulkukeskus":
        return require("../themes/kulkukeskus/kulkukeskus_logo.png");
      case "keskustaksi":
        return require("../themes/keskustaksi/logo-keskustaksi.png");
      case "th":
        return require("../themes/th/logo-taksihelsinki.png");
      case "fimlab":
        return require("../themes/fimlab/logo-fimlab.png");
      case "lahitaksi":
        return require("../themes/lahitaksi/logo-lahitaksi.png");
      default:
        break;
    }
  }

  @Watch("themeId", { immediate: true })
  onThemeIdChanged(val: string, oldVal: string) {
    if (!this.theme) {
      throw new Error("Theme was not found with theme id " + val);
      return;
    }
    Object.assign(this.$vuetify.theme.themes.light, this.theme.vuetifyTheme);
  }

  toggleTheme() {
    const themeIndex = this.themes.findIndex((t) => t.id === this.themeId);
    const newThemeIndex =
      themeIndex < this.themes.length - 1 ? themeIndex + 1 : 0;
    const newThemeId = this.themes[newThemeIndex].id;
    this.$store.dispatch("app/setThemeId", newThemeId);
  }

  get themeCssClassName() {
    return this.theme.cssClassName;
  }

  get currentRoute(): Route {
    return this.$route;
  }

  get currentNavigationItemIndex(): number {
    const index = this.navigationItems.findIndex(i => i.url === this.currentRoute.path);
    return index;
  }
  
  get navigationItems() {
    let items = this.theme.menuNavigationItems;

    const messages = items.find((i) => i === NavigationItems.messages);
    if (messages) {
      messages.badge = this.showMessages;
      messages.badgeContent = this.unseenMessages;
    }
    if (this.userHasPassword) {
      items = items.concat([NavigationItems.changePassword]);
    } else {
      items = items.concat([NavigationItems.createPassword]);
    }
    items = items.concat([NavigationItems.logout]);

    items = items
      .filter((item) => this.showMessages || item.url !== "/messages")
      .filter((item) => !item.requireAuth || this.isAuthenticated);
    return items;
  }

  get isAuthenticated() {
    return this.$store.getters["auth/isAuthenticated"];
  }

  get userHasPassword() {
    return this.$store.getters["app/userHasPassword"];
  }

  get title(): TranslateResult {
    switch (this.$store.state.route.name) {
      case "terms": {
        return this.$t("menu.terms_of_service");
      }
      default: {
        return this.$tt("sitetitle");
      }
    }
  }

  get currentBottomNavigationItemIndex(): number {
    const index = this.bottomNavItems.findIndex(i => i.url === this.currentRoute.path);
    return index;
  }

  get bottomNavItems(): INavigationItem[] {
    return this.theme.bottomNavNavigationItems.filter(
      (item) => this.showMessages || item.url !== "/messages"
    );
  }

  get bottomNav() {
    const path = this.$store.state.route.path;
    const index = this.theme.bottomNavNavigationItems.findIndex(
      (i) => i.url === path
    );
    return index >= 0 ? index : undefined;
  }

  get bottomNavColor() {
    if (this.theme) {
      const color = this.theme.vuetifyTheme.bottomNavColor;
      return color ? color : "#ffffff";
    }
    return "#ffffff";
  }

  get showMessages() {
    return this.$store.state.message.messages.length > 0;
  }

  get unseenMessages() {
    return this.$store.getters["message/unseenCount"];
  }

  get showMessageBadge() {
    return this.unseenMessages > 0;
  }

  onJumpToContentClick(e: any) {
    const ref = this.$refs.appContentMainRef;
    if (ref) {
      (ref as any).focus();
    }
  }

  menuTabPressed(event: any) {
    MenuHelper.menuTabPressed(event, this);
  }

  page(name: string) {
    if (name !== this.$store.state.route.path) {
      this.$router.push(name);
    }
  }

  logout() {
    this.$store.dispatch("auth/logout");
  }

  globalKeyDownHandler(event: KeyboardEvent) {
    // Implemented for a11y #5244
    if (event.code === "Escape") {
      this.drawer = false;
    }
  }

  onNavOpenClick(){
    this.drawer = !this.drawer; 
    // window.setTimeout(() =>  {
    //     let mainMenuFocusElem = document.getElementById('main-menu-title') as HTMLElement;
    //     if (mainMenuFocusElem){
    //       mainMenuFocusElem.focus();
    //     } else {
    //       let firstMenuItem = document.getElementById('main-menu-item-0');
    //       if (!firstMenuItem){
    //         const listElement = (this.$refs.navListRef as any).$el as HTMLElement;
    //         const listBoxElement = (listElement.firstElementChild) as HTMLElement;
    //         firstMenuItem = (listBoxElement.firstElementChild as HTMLAnchorElement);
    //       }
    //       firstMenuItem.focus();
    //     }
    // }, 200);
  }

  onNavCloseClick(){
    this.drawer = !this.drawer; 
    // window.setTimeout(() =>  {
    //   (this.$refs.navOpenButtonRef as any).$el.focus();
    // }, 120);
  }

  onNavItemClick(item: INavigationItem){
    this.drawer = false;
    window.setTimeout(() => {
      var top = document.getElementById("top") as HTMLElement;
      if (top){
        top.focus();
      }
    }, 100); 
  }

  async created() {
    // begin temp fix: 
    // redirect /#/invitation/xxx urls to /invitation/xxx
    var currentUrl = document.location.href;
    if (currentUrl.includes("/#/invitation/")){
      console.warn("Redirecting a legacy URL with /#/invitation to /invitation");
      document.location.href = currentUrl.replace("/#/invitation/", "/invitation/");
    }
    // end temp fix
    (window as any)._approot = this;
    await this.initApp();
  }

  mounted() {
    this.themeToggleEnabled =
      document.location.host.indexOf("localhost") > -1 ||
      document.location.host.indexOf("127.0.0.1") > -1 ||
      document.location.host.indexOf("192.168.8.107") > -1 ||
      document.location.host.indexOf("staging") > -1 ||
      document.location.hash.indexOf("dev_theme_testing") > -1 ||
      document.location.host.indexOf(".ngrok.io") > -1;

    window.addEventListener("keydown", this.globalKeyDownHandler);
  }

  destroyed() {
    window.removeEventListener("keydown", this.globalKeyDownHandler);
  }

  async initApp() {
    try {
      await this.$store.dispatch("app/initializeApp");
    } catch (e) {
      // if (e.response.status === 401) {
      //   // TODO
      // }
    }
    this.isLoading = false;
  }
}
