export function programmaticFocus (el: HTMLElement, timeout = 1000) {
  const originalTabIndex = el.getAttribute('tabindex')
  el.setAttribute('tabindex', '-1')
  el.focus();
  if (!originalTabIndex){
    setTimeout(() => el.removeAttribute('tabindex'), timeout);
  } else {
    setTimeout(() => el.setAttribute('tabindex', originalTabIndex), timeout);
  }
}

export const focusedTags = new RegExp('^(a|select|input|button|textarea)', 'i');
