import Vue from 'vue';
import sanitizeHTML from 'sanitize-html';

/**
 * Sanitize dirty HTML, with default settings.
 */
Vue.prototype.$sanitize = (dirty: string) => sanitizeHTML(dirty, {
  allowedTags: [ 'b', 'i', 'strong', 'br' ],
  allowedAttributes: {},
});
