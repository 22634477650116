import Vue from 'vue';
// import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
//  import { fas } from '@fortawesome/free-solid-svg-icons';

//  import '@fortawesome/fontawesome-free/css/all.css';
//  import '@fortawesome/fontawesome-free/js/all.js';

Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
// library.add(fas); // Include needed icons

// Import autogenerated fontawesome icons
import { CUSTOM_ICONS } from "@/plugins/fontawesome-autogen"; 
// Manually import some framework icons not autodetected
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faBars,
  faExclamationTriangle,
  faExclamation,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faCheckSquare,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
library.add(
	faBars,
  faExclamationTriangle,
  faExclamation,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faCheckSquare,
  faCheckCircle
);
import {
  faCircle,
  faDotCircle,
  faSquare
} from '@fortawesome/free-regular-svg-icons';
import { Iconfont } from 'vuetify/types/services/icons';
library.add(
  faSquare,
  faCircle,
  faDotCircle
);

const VuetifyFontAwesomeIconsConfig = {
    iconfont: 'faSvg' as Iconfont,
    values: CUSTOM_ICONS
};
export { VuetifyFontAwesomeIconsConfig };