import Vue from 'vue';
import Vuex, { Module, ModuleTree } from 'vuex';
import appStore, { IAppState } from './app-store';
import authStore, { IAuthState } from './auth-store';
import passengerStore, { IPassengerState } from './passenger-store';
import tripStore, { ITripState } from './trip-store';
import messageStore, { IMessageState } from './message-store';
import noticeStore, { INoticeState } from './notice-store';
import quotaStore from './quota-store';

Vue.use(Vuex);

export interface IRootState {
  app: IAppState;
  auth: IAuthState;
  passenger: IPassengerState;
  trip: ITripState;
  message: IMessageState;
}

const modules: ModuleTree<IRootState> = {
  app: appStore,
  auth: authStore,
  passenger: passengerStore,
  trip: tripStore,
  message: messageStore,
  notice: noticeStore,
  quota: quotaStore,
};

const getPersistedPaths = (mods: ModuleTree<IRootState>) => {
  let paths: string[] = [];
  Object.keys(mods).forEach(modName => {
      const modPaths: string[] = mods[modName].state.persist;
      if (modPaths) {
          paths = paths.concat(modPaths.map(p => modName + '.' + p));
      }
  });
  return paths;
};

// PWA. Workbox will use staleWhileRevalidate strategy with broadcastUpdate.Plugin
// to route API requests. Reqister to receive updates and trigger vuex events.
// https://developers.google.com/web/tools/workbox/modules/workbox-broadcast-cache-update
const handleCacheUpdate = async (event: MessageEvent) => {
  // console.log("store: handleCacheUpdate for workbox event");
  // Optional: ensure the message came from workbox-broadcast-update
  if (event.data.meta !== 'workbox-broadcast-update') {
    // console.log("  event.meta does not indicate workbox update, ignoring ...")
    return;
  }

  const {cacheName, updatedURL} = event.data.payload;
  /**
   * Function to fetch update data from cache
   * @param regex For matching the API path
   * @param callback What to do with the returned data if regex mathced the update
   */
  const readDataFromCache = async <T>(regex: string, callback: (data: T) => void) => {
    const reg = new RegExp(regex);
    if (updatedURL.match(reg)) {
      const cache = await caches.open(cacheName);
      const updatedResponse = await cache.match(updatedURL);
      if (updatedResponse) {
        const data: T = await updatedResponse.json();
        callback(data);
      }
    }
  };
  // Handle passenger update
  readDataFromCache<IPassenger[]>('/api/passenger$', passengers => {
    passengers.forEach(passenger => store.commit('passenger/passengerReplaceOrAdd', passenger));
  });
  // Handle trips update for a passenger
  readDataFromCache<ITrip[]>('/api/passenger/(.*)/trips$', trips => {
    trips.forEach(t => store.commit('trip/addOrReplaceTrip', t));
  });
};

// Updated for workbox >= v5
if (typeof(navigator.serviceWorker) === 'object') {
  navigator.serviceWorker.ready.then(() => {
    navigator.serviceWorker.addEventListener('message', handleCacheUpdate);
  });
}

// Try to keep track of the current network state
window.addEventListener('load', () => {
  function handleNetworkChange() {
    store.commit('app/setOnline', navigator.onLine);
  }
  window.addEventListener('online', handleNetworkChange);
  window.addEventListener('offline', handleNetworkChange);
});

const store = new Vuex.Store({
  modules,
});
export default store;
