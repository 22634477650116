import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

import { faMapMarker as fasMapMarker } from '@fortawesome/free-solid-svg-icons';
import Stroke from 'ol/style/Stroke';

export default class PlaceMarkerFeature extends Feature {
  constructor(coordinates: Coordinate, markerType: EPlaceMarkerType, options?: any) {
    super(options);
    this.setGeometry(new Point(coordinates));
    
    const iconStyle = this.createIconStyle(markerType, 1, [0.5, 1]);
    iconStyle.setZIndex(1);
    const iconShadowStyle = this.createIconStyle('#333333', 0.75, [2.5, -3]);
    iconStyle.setZIndex(0);


    this.setStyle([iconShadowStyle, iconStyle]);
  }

  createIconStyle(color: string, opacity: number = 1.0, displacement: number[] = [0, 0], width: string = '27px', height: string = '36px') {
    const path = fasMapMarker.icon[4];
    const iconColor = color.replace('#', '%23');
    const strokeColor = '%23333333';
    const svgElement = 
      `<svg aria-hidden="true" width="${width}" height="${height}" focusable="false" data-prefix="fas" data-icon="location-pin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">`
        +`<path fill="${iconColor}" d="${path}" opacity="${opacity}" class=""></path>` 
      +`</svg>`;

    const icon = new Icon({ src: 'data:image/svg+xml;utf8,' + svgElement, displacement});

    const style = new Style({  image: icon });

    return style;
  }
}

export enum EPlaceMarkerType {
  Start = '#1f890e',
  ViaPoint = '#FFCC00',
  End = '#F44336',
}
