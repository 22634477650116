export default {
    decodeToken(token: string) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        } catch (e) {
            return undefined;
        }
    },
    expiresIn(token: string) {
        const tokenData = this.decodeToken(token);
        if (!tokenData || !tokenData.exp) {
            // If token is not valid or it doesn't have exp value
            return Number.MIN_SAFE_INTEGER;
        }
        const currentTime = new Date().getTime() / 1000;
        const expiresIn = Math.floor(tokenData.exp - currentTime);
        return expiresIn;
    },
};
