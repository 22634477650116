















import VueSkipToSingle from './VueSkipToSingle.vue'
import VueSkipToList from './VueSkipToList.vue'
import { programmaticFocus, focusedTags } from './util'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: "VueSkipTo"
})
export default class VueSkipTo extends Vue {

  @Prop({default: 'Skip to'})
  listLabel!: string;
   
  @Prop({default: 'Skip to main content'})
  label!: string;
    
  @Prop({default: 'main'})
  to!: string | Array<{ anchor: string, label: string, ariaLabel: string }>;

  @Prop({default: 'top'})
  top!: string | Array<{ anchor: string, label: string, ariaLabel: string }>;

  focused = false;
   
  get isList () {
    return Array.isArray(this.to)
  }

  get comp () {
    return this.isList ? VueSkipToList : VueSkipToSingle
  }

  get props () {
    if (this.isList) return { listLabel: this.listLabel, to: this.to }
    return { label: this.label, to: this.to }
  }

  focusWithin(val: boolean) {
    this.focused = val
  }

  mounted () {
    if (this.$route) {
      this.$watch('$route.path', () => {
        // const skipTo = this.$refs.skipTo;
        // if (skipTo){
        //   const el = (skipTo as Vue).$el;
        //   this.$nextTick(() => programmaticFocus(skipTo as HTMLElement))
        // }
        if (typeof(this.top) === "string"){
          const element = window.document.getElementById(this.top)
          if (!element) {
            return;
          }
          if (!focusedTags.test(element.tagName.toLowerCase())){
            this.$router.replace({ hash: this.top });
            return programmaticFocus(element);
          }
        }

      })
    }
  }
}
