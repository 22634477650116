






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class QuotaChip extends Vue {
  @Prop({ required: false }) quota!: number;
  @Prop({ default: '' }) text!: string;

  get hide() {
    return typeof(this.quota) === 'undefined' || this.quota === null;
  }

  get color() {
    if (this.quota) {
      return 'success';
    } else {
      return 'error';
    }
  }
}
