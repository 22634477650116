import store from '@/store/store';
import ITheme from '@/themes/ITheme';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Geometry, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import { faTaxi as fasTaxi } from '@fortawesome/free-solid-svg-icons';
import Icon from 'ol/style/Icon';
import Circle from 'ol/style/Circle';

export default class CarLayer extends VectorLayer<VectorSource<Geometry>> {
  constructor(carCoordinates: Coordinate, options: any = { className: 'mapCar' }) {
    super(options);

    const feature = new Feature({
      geometry: new Point(carCoordinates),
      style: this.getCarLayerStyles(),
    });

    feature.setId('carFeature');

    const features = [feature];
    this.setSource(new VectorSource({ features }));
  }

  public updateCoordinates(coordinates: Coordinate) {
    const feature = this.getSource()?.getFeatureById('carFeature');
    if (feature) {
      feature.setStyle(this.getCarLayerStyles());
      feature.setGeometry(this.getGeometry(coordinates));
    }
  }

  private getGeometry(coordinates: Coordinate) {
    return new Point(coordinates);
  }

  private getCarLayerStyles() {
    const theme: ITheme = store.getters['app/theme'];

    const carStyle = this.createIconStyle(theme.vuetifyTheme.bottomNavColor, 1, [0, 0]);
    carStyle.setZIndex(1);

    const circleStyle = new Style({ 
      image: new Circle({ 
        radius: 16, 
        fill: new Fill({ 
          color: theme.vuetifyTheme.primary 
        }), 
      }),
    });
    circleStyle.setZIndex(0);

    const shadowCircleStyle = new Style({
      image: new Circle({
        radius: 18,
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.75)',
        }),
      }),
    })

    return [shadowCircleStyle, circleStyle, carStyle];
  }
  

  createIconStyle(color: string, opacity: number = 1.0, displacement: number[] = [0, 0], width: string = '20px', height: string = '20px') {
    const path = fasTaxi.icon[4];
    const iconColor = color.replace('#', '%23');
    const svgElement = 
      `<svg aria-hidden="true" width="${width}" height="${height}" focusable="false" data-prefix="fas" data-icon="taxi" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">`
        +`<path fill="${iconColor}" d="${path}" opacity="${opacity}" class=""></path>` 
      +`</svg>`;

    const icon = new Icon({ src: 'data:image/svg+xml;utf8,' + svgElement, displacement, anchorOrigin: 'bottom-right'});
    const style = new Style({  image: icon });
    return style;
  }

}
