


































import PassengerTransportationPermission from '@/views/PassengerTransportationPermission.vue';
import {Vue, Prop, Component} from 'vue-property-decorator';
@Component({
    components: {
      PassengerTransportationPermission
    }
})
export default class TransportationPermissionListItem extends Vue {
  @Prop({required: true}) permission!: IBookingQuotaWithPeriods;

  viewPermissionDialog: boolean = false;

  get startDate() {
    if (this.permission) {
        return this.$moment(this.permission.startDate).format('DD.MM.YYYY');
    } 
    return '';
  }

  get endDate() {
    if (this.permission && this.permission.endDate) {
        return this.$moment(this.permission.endDate).format('DD.MM.YYYY');
      } 
    return '';
  }

}
