
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class TimePicker extends Vue {

  formatted: string | null =  null;
  hasError: boolean = false;
  lastEmitted: number | null | undefined = null;

  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) ariaLabel!: string;
  @Prop({default: undefined}) from: number | undefined;
  @Prop() private value!: number;
  @Prop({ default: null }) rules!: any[];
  @Prop({default: false}) required!: boolean;
  @Prop({ default: false }) autofocus!: boolean;

  get formattedTime() {
    if (this.formatted) {
      return this.formatted;
    } else {
      return '';
    }
  }

  set formattedTime(time: string) {
    this.formatted = this.formatTime(time);
  }

  get isValid() {
    return !(this.$refs.timePicker as any).hasError;
  }

  @Watch('value', { immediate: true }) onValueChange(newVal: number, prevVal: number) {
    if (!isNaN(Number(this.value)) && this.value !== null && newVal !== this.lastEmitted) {
      // Set default times, adding 0 if numeric value under 10
      const hoursNum = Math.floor(this.value / 60) % 24;
      const hours = hoursNum < 10 ? '0' + hoursNum : hoursNum + '';
      const minutesNum = this.value % 60;
      const minutes = minutesNum < 10 ? '0' + minutesNum : minutesNum + '';

      this.formatted = this.formatTime(`${hours}:${minutes}`);
    }
  }

  @Watch('formatted')onFormattedChange(val: any) {
    let emitValue;
    if (!!val) {
      const timeSplit = this.formattedTime.split(':');
      const hours = Number(timeSplit[0]);
      const minutes = Number(timeSplit[1]);
      let timeValue = (hours * 60) + minutes;

      if (this.from && this.from > timeValue) {
        timeValue += 1440;
      }

      emitValue = this.hasError ? NaN : timeValue;
    }

    this.lastEmitted = emitValue;
    this.$emit('input', emitValue);
  }

  formatTime(time: string) {
    // Clear non numeric parts of the string.
    time = time.replace(/\D/g, '');

    if (time.length > 4) {
      time = time.substring(0, 4);
    }

    const hours = time.length > 2 ? time.substring(0, time.length - 2) : '';
    const minutes = time.substring(time.length - 2, time.length);

    this.hasError = Number(hours) > 23 || Number(minutes) > 59;

    return hours || minutes ? `${hours}:${minutes}` : '';
  }

}
