




































































import moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "TripListItem"
})
export default class TripListItem extends Vue {
  @Prop() trip!: ITrip;

  get avatarColor() {
    if (this.trip.cancelledAt) {
      return "#444444";
    } else {
      return this.$randomColor(this.trip.passenger.id);
    }
  }

  get startAddress() {
    if (this.trip.startAddress) {
      return [this.trip.startAddress.alias, this.trip.startAddress.address]
        .map((s) => (s || "").trim())
        .filter((s) => !!s)
        .join(", ");
    }
    return this.trip.startPlace;
  }

  get endAddress() {
    if (this.trip.endAddress) {
      return [this.trip.endAddress.alias, this.trip.endAddress.address]
        .map((s) => (s || "").trim())
        .filter((s) => !!s)
        .join(", ");
    }
    return this.trip.endPlace;
  }

  getViaPointAddress(via: IViaPoint) {
    return [via.viaPointAddress?.alias, via.viaPointAddress?.address]
      .map((s) => (s || "").trim())
      .filter((s) => !!s)
      .join(", ");
  }
}
